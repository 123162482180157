import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PiCameraFill } from "react-icons/pi";
import { IoVideocam } from "react-icons/io5";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import PickupAndDelivery from "../components/PickupAndDelivery";
import HealthAndSafety from "../components/HealthAndSafety";
import EnterInformation from "../components/EnterInformation";
import ReviewAndPay from "../components/ReviewAndPay";
import Timeline from "../components/Timeline";
import { useAllPups } from "../features/pups/pupsService";
import { getPuppyAgeInWeeks } from "../utils";
import GlobalLoader from "../components/GlobalLoader";
import { useCreateOrders } from "../features/Orders/OrdersService";
import { Spinner, useToast } from "@chakra-ui/react";
import { useAllProducts } from "../features/Products/productsService";
import { useQueryClient } from "@tanstack/react-query";
import {
  setCurrentStep,
  setFormData,
  resetBuyNowState,
} from "../features/buy-now/action";
import { useAllDiscounts } from "../features/DiscountsCoupon/discountServise";

const BuyNow = () => {
  const dispatch = useDispatch();
  const {
    currentStep,
    formData,
    isStep3Done,
    isStep2Done,
    localProducts,
    addedProducts,
    zipCode,
  } = useSelector((state) => state.buyNow);

  const [loading, setLoading] = useState(false);
  const createMutation = useCreateOrders();
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: products = [],
    isLoading: isLoadingProducts,
    error: errorProducts,
  } = useAllProducts("/api/products");
  const {
    data: pups = [],
    isLoading: isLoadingPups,
    error: errorPups,
  } = useAllPups("/api/puppies");
  const {
    data: discounts,
    isLoading: isLoadingDiscounts,
    error: errorDiscounts,
  } = useAllDiscounts("/api/discounts");
  const isLoading = isLoadingProducts || isLoadingPups;
  const error = errorProducts || errorPups;

  if (isLoading) {
    return <GlobalLoader />;
  }

  if (error) {
    return <div>Error loading pups data: {error.message}</div>;
  }

  const puppeisFilter = pups?.filter((prod) => prod._id === id);
  const puppeis = puppeisFilter[0];
  const bredName = puppeis?.puppyInformation?.breed?.breedInformation.breedName;

  const filteredCouponss =
    discounts?.filter((coupon) => {
      const isValidBreed = coupon?.restrictedBreeds?.includes(bredName);
      return isValidBreed;
    }) || [];

  // Filter coupons

  const highestValueCoupon = filteredCouponss?.reduce((highest, current) => {
    const currentValue = parseFloat(current.value);
    const highestValue = highest ? parseFloat(highest.value) : 0;

    return currentValue > highestValue ? current : highest;
  }, null);

  console.log("highestValueCoupon", highestValueCoupon);
  console.log("filteredCoupons", filteredCouponss);

  const steps = [
    {
      component: <PickupAndDelivery />,
      label: "Pickup/Delivery",
    },
    {
      component: <HealthAndSafety products={products} />,
      label: "Health & Safety",
    },
    {
      component: <EnterInformation />,
      label: "Enter Information",
    },
    {
      component: (
        <ReviewAndPay
          breed={bredName}
          puppeis={puppeis}
          highestValueCoupon={highestValueCoupon}
        />
      ),
      label: "Review & Pay",
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      window.scrollTo(0, 0);
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      window.scrollTo(0, 0);
      dispatch(setCurrentStep(currentStep - 1));
    }
  };

  const handleConfirmOrder = async () => {
    setLoading(true);

    console.log("formData:", formData);

    if (!formData) {
      console.error("Form data is missing");
      toast({
        title: "Error: Form data is missing",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    const orderData = {
      puppies: [id], // Assuming id is the puppyId
      zipcode: formData.zipCode,
      deliveryMethod: formData.deliveryMethod,
      pickupPoint: formData.pickupPoint,
      products: formData.products || [],
      orderSummary: {
        deliveryMethodCharge: 0, // You'll need to calculate this
        subtotal: formData.subTotal,
        coupon: formData.coupon, // Update this if you have coupon functionality
        discount: formData.discount, // Update this if you have discount functionality
        salesTax: formData.salesTax, // You'll need to calculate this
        orderTotal: formData.totalPrice,
      },
      contactInformation: {
        emailAddress: formData.email,
        cellPhone: formData.phone,
      },
      destinationAddress: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zip: formData.zipCode,
        // driversLicenseNumber: formData?.driversLicenseNumber,
        // instagramUsername: formData?.instagramUsername,
      },
      billingAddress: {
        sameAsDestination: true,
      },
      paymentOptions: {
        payType: formData.paymentType,
        method: formData.paymentMethod,
      },
      agreementTerms: {
        salesFinal: false,
        termsOfUse: false,
        receiveSMS: false,
      },
    };

    console.log("orderData:", orderData);

    try {
      const response = await createMutation.mutateAsync({
        apiUrl: "/api/order",
        data: orderData,
      });
      console.log("API response:", response);

      // navigate("/breeds");
      navigate("/confirm");

      dispatch(resetBuyNowState());
    } catch (error) {
      console.error("Error in handleConfirmOrder:", error);
      console.error("Error response:", error.response?.data);
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Order Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const onHandleNext = () => {
    if (formData.deliveryMethod || formData.pickupPoint) {
      nextStep();
    } else {
      toast({
        title: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="poppins-regular relative h-full">
      <div
        className="absolute left-0 top-0 h-[900px] w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/top-banner-breeds.png')" }}
      ></div>

      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-56 md:px-32">
        <h6 className="pb-8 text-5xl">Buy Now</h6>
        <p>
          Follow the sequence and fill out the details accordingly for
          successful buying.
        </p>
        <div className="my-20 flex flex-col items-center justify-center gap-10 md:flex-row">
          <div className="relative">
            <div className="h-[480px] max-w-[652px] overflow-hidden bg-center">
              <img
                src={
                  puppeis?.listingInformation?.photos?.[0] ||
                  "https://storage.googleapis.com/cavapoops-8aaab.appspot.com/pet1.png"
                }
                alt={puppeis?.listingInformation?.name || "Product Image"}
                className="h-full w-full overflow-hidden rounded-[80px] object-cover"
              />
            </div>
            <div className="absolute left-0 top-0 m-8 flex items-center justify-center gap-3">
              <PiCameraFill
                size={32}
                className="flex h-10 w-10 cursor-pointer items-start rounded-full bg-[#EDEDE9] px-2 py-2 duration-200"
              />
              <IoVideocam
                size={32}
                className="flex h-10 w-10 cursor-pointer items-start rounded-full bg-[#EDEDE9] px-2 py-2 duration-200 hover:w-64"
              />
            </div>
          </div>
          <div className="flex flex-row justify-around md:min-h-[480px] md:flex-col">
            <div>
              <p className="mb-9 text-3xl font-semibold">
                {puppeis?.listingInformation?.name || "Product Name"}
              </p>
              <p className="text-2xl font-semibold">
                {puppeis?.puppyInformation?.breed?.breedInformation
                  ?.breedName || "Breed Name"}
              </p>
              <p className="text-lg font-medium">
                {puppeis?.puppyInformation?.weight || "Weight"} |{" "}
                {puppeis?.listingInformation?.adultWeight || "Adult Weight"} @
                {getPuppyAgeInWeeks(puppeis?.puppyInformation?.dateOfBirth) ||
                  "N/A"}{" "}
                weeks
              </p>
            </div>
            <div>
              <p className="mb-8 inline-block rounded-[100px] bg-[#001219] px-8 py-3 text-lg font-semibold text-white">
                Save ${parseInt(highestValueCoupon?.value)}
              </p>
              <p className="pl-4 text-2xl font-medium line-through">
                ${puppeis?.listingInformation?.price || "Price"}
              </p>
              <p className="pl-4 text-3xl font-semibold">
                $
                {puppeis?.listingInformation?.price -
                  highestValueCoupon?.value || "Price"}
              </p>
            </div>
          </div>
        </div>
        {/* Timeline */}
        <Timeline steps={steps} currentStep={currentStep} />

        {/* Flow Container */}
        <div className="flow-container max-w-[800px]">
          {steps[currentStep]?.component}

          <div className="my-10 flex flex-col items-center justify-center gap-10 md:flex-row">
            {currentStep > 0 && (
              <button
                onClick={prevStep}
                className={`${currentStep === 2 && "relative right-[6rem]"} flex w-[190px] items-center justify-center gap-3 rounded-full bg-[#001219] px-6 py-5 text-white duration-200 hover:gap-6`}
              >
                <HiOutlineArrowNarrowLeft size={22} />
                Previous
              </button>
            )}
            {currentStep < steps.length - 1 &&
              (formData.deliveryMethod || formData.pickupPoint) &&
              currentStep !== 2 && (
                <button
                  onClick={onHandleNext}
                  className="flex w-[190px] items-center justify-center gap-3 rounded-full bg-[#001219] px-6 py-5 text-white duration-200 hover:gap-6"
                >
                  Go Next
                  <HiOutlineArrowNarrowRight size={22} />
                </button>
              )}
            {currentStep === 3 && (
              <button
                onClick={handleConfirmOrder}
                className={`flex min-w-[190px] items-center justify-center gap-3 rounded-full bg-[#207c2b] px-8 py-5 text-white duration-200 hover:gap-6 ${loading && "cursor-not-allowed opacity-50"}`}
              >
                Confirm Order
                {loading && (
                  <Spinner
                    thickness="3px"
                    speed="0.45s"
                    color="white"
                    size="md"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
