import React from "react";
import OrderDetails from "../summary/innertabpages/OrderDetails";
import CustomerInfo from "../sales/innertabpages/CustomerInfo";
import MGRAlert from "../sales/innertabpages/MGRAlert";
import VetReview from "./innerTabPages/VetReview";
import Kenneling from "../sales/innertabpages/Kenneling";
import Timelines from "../sales/innertabpages/Timelines";
import Refund from "../sales/innertabpages/Refund";
import Conversations from "../sales/innertabpages/Conversations";
import ChakraTabs from "../../../../../../components/ChakraTabs";
import BloodTest from "../summary/innertabpages/BloodTest";
import FecalTest from "./innerTabPages/FecalTest";
import CheckCalls from "./innerTabPages/CheckCalls";
import VetCheck from "./innerTabPages/VetCheck";
import HealthConcern from "./innerTabPages/HealthConcern";

const Health = ({ data }) => {
  const tabDataPrimary = [
    {
      label: "Order Details",
      content: <OrderDetails data={data} />,
    },
    {
      label: "Customer Info",
      content: <CustomerInfo />,
    },
    {
      label: "MGR Alert",
      content: <MGRAlert />,
    },
    {
      label: "Vet Review",
      content: <VetReview />,
    },
    {
      label: "Kenneling",
      content: <Kenneling />,
    },
    {
      label: "Timeline",
      content: <Timelines />,
    },
  ];
  const tabDataSecondary = [
    {
      label: "Vet Check",
      content: <VetCheck />,
    },
    {
      label: "Blood Test",
      content: <BloodTest />,
    },
    {
      label: "Fecal Test",
      content: <FecalTest />,
    },
    {
      label: "Check Calls",
      content: <CheckCalls />,
    },
  ];

  const tabDataTertiary = [
    {
      label: "Health Concern",
      content: <HealthConcern />,
    },
    {
      label: "Refund",
      content: <Refund />,
    },
    {
      label: "Conversation",
      content: <Conversations />,
    },
  ];
  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      <div className="col-span-2 flex flex-col gap-10">
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataSecondary} />
        </div>
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataTertiary} />
        </div>
      </div>
    </div>
  );
};

export default Health;
