import React from "react";

const ExtHoldEnb = () => {
  return (
    <div className="h-full mt-6">
      <div className="flex flex-1 flex-col items-center justify-center">
        <p className="text-xl font-extrabold">8/3/2024</p>
        <p className="text-lg font-bold">Extended Holding Enabled date </p>
      </div>
    </div>
  );
};

export default ExtHoldEnb;
