import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_PAGES } from "./pageTypes"
import { create, Delete, fetchAll, update } from "../Apis/Apis"





export const useAllPages = (apiUrl) => {
    return useQuery({
      queryKey: [GET_ALL_PAGES],
      queryFn: () => fetchAll(apiUrl),
    });
  };

export const  useCreatePage = ()=>{
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: (new_page)=> {
            queryClient.invalidateQueries(GET_ALL_PAGES)
        },
        onError: (error, context, new_page)=>{
            console.log('error creating page', error)
        },
        onSuccess: (data)=> {
            queryClient.cancelQueries(GET_ALL_PAGES)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_PAGES)
        }
    })
}

export const useUpdatePage = ()=> {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (new_page)=>{
            queryClient.invalidateQueries(GET_ALL_PAGES)
        },
        onError: (error, context, new_page)=>{
            console.log('error updating page', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_PAGES)
        },
        onSettled: ()=>{
            queryClient.invalidateQueries(GET_ALL_PAGES)
        }
    })
}

export const useDeletePage = ()=>{
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: ()=>{
            queryClient.invalidateQueries(GET_ALL_PAGES)
        },
        onError: (error, context, new_page)=>{
            console.log('error deleting page', error)
        },
        onSuccess: (data)=> {
            queryClient.invalidateQueries(GET_ALL_PAGES)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_PAGES)
        }
    })
}