import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  AiFillEdit,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllFAQS,
  useAllFAQS_CATEGORY,
  useCreateFAQS,
  useDeleteFAQSMutation,
  useUpdateFAQSMutation,
} from "../../../features/Faqs/faqsService.js";
import GlobalLoader from "../../../components/GlobalLoader";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import FaqsForm from "./FaqsForm.jsx";
import AntModal from "../../../components/AntModal.jsx";

const ConfirmCancelButtons = ({ onConfirm, onCancel }) => (
  <div className="flex gap-5 pt-2">
    <button
      className="rounded-full bg-[#35f8fe] p-1 text-white"
      onClick={onConfirm}
    >
      <AiFillCheckCircle />
    </button>
    <button
      className="rounded-full bg-[#EE5D50] p-1 text-white"
      onClick={onCancel}
    >
      <AiFillCloseCircle />
    </button>
  </div>
);

const Questions = () => {
  const [rowData, setRowData] = useState([]);
  const [newData, setNewData] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [isOpen2, setIsOpen2] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);

  const onOpen2 = () => setIsOpen2(true);
  const onClose2 = () => setIsOpen2(false);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: questions, isLoading, error } = useAllFAQS("/api/faqs");

  console.log(questions, "questions");
  const {
    data: faqsCategories,
    isLoading: isLoadingCategories,
    error: errorCategories,
  } = useAllFAQS_CATEGORY("/api/faq-categories");

  const updateMutation = useUpdateFAQSMutation();
  const deleteMutation = useDeleteFAQSMutation();

  const handleConfirm = () => {
    setIsModalVisible(false)
    console.log('confirm');
    deleteMutation.mutate(
      { apiUrl: `/api/faq/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Faqs deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error deleting Faqs",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  }

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
    };
    

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handleEdit}
        >
          <AiFillEdit />
        </button>
        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={handleDeleteClick}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const handleDelete = () => {
 
    setActionType("delete");
    setIsModalVisible(true);

  };

  const handleUpdate = () => {
    const filter = questions?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
    onOpen2(true)
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  useEffect(() => {
    if (questions) {
      setRowData(questions);
    }
  }, [questions]);

  const gridRef = useRef();

  if (isLoading || isLoadingCategories) return <GlobalLoader />;
  if (error || errorCategories) {
    toast({
      title: "Error fetching data.",
      description: "There was an error loading the questions or categories.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  const columns = [
    { headerName: "ID", field: "_id", hide: true },
    { headerName: "Question", field: "question", editable: true },
    { headerName: "Answer", field: "answer", editable: true },
    {
      headerName: "Category",
      field: "category.category",
      editable: true,
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  return (
    <div className="my-5">
      <button
        onClick={onOpen}
        className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2 hover:bg-black hover:text-white my-2"
      >
        + Add New Record
      </button>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          ref={gridRef}
          modules={[ClientSideRowModelModule]}
          rowData={rowData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="single"
          domLayout="autoHeight"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
          onCellValueChanged={(params) => {
            setNewData(params.data);
          }}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <FaqsForm
          onClose={onClose}
          // isUpdateModal={true}
          // selectedData={selectedData}
        />
      </GlobalModalV1>

      <GlobalModalV1 isOpen={isOpen2} onClose={onClose2} width={"800px"}>
        <FaqsForm
          onClose={onClose2}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this faqs?"
            : "Are you sure you want to delete this faqs?"
        }
        okText={"Confirm"}
      />

    </div>
  );

 

};

export default Questions;
