import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import ProductsForm from "./OrdersForm.jsx";
import {
  useAllProducts,
  useDeleteProductsMutation,
} from "../../../features/Products/productsService.js";
import {
  useAllOrders,
  useDeleteOrdersMutation,
  useUpdateOrdersMutation,
} from "../../../features/Orders/OrdersService.js";
import OrdersDashboard from "./OrderDetail/OrdersDashboard.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrdersTabs from "../OrderTabs.jsx";
import AntModal from "../../../components/AntModal.jsx";

const Pending = () => {
  const { data: orders, isLoading, error } = useAllOrders("/api/orders");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isShowDetail, setIsShowDetail] = useState({});
  const [isToggled, setIsToggled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAction, setIsAction] = useState('approve')
  const [actionType, setActionType] = useState('approve');
  const navigate = useNavigate();
  const updateMutation = useUpdateOrdersMutation();

  const transformOrders =
    orders?.map((order, index) => ({
      _id: order?._id,
      id: index + 1,
      firstName: order?.destinationAddress?.firstName ?? "",
      lastName: order?.destinationAddress?.lastName ?? "",
      cellPhone: order?.contactInformation?.cellPhone ?? "",
      orderDate: moment(order?.createdAt).format("DD-MM-YY") ?? "",
      subtotal: order?.orderSummary?.subtotal ?? 0,
      discount: order?.orderSummary?.discount ?? 0,
      salesTax: order?.orderSummary?.salesTax ?? 0,
      orderTotal: order?.orderSummary?.orderTotal ?? 0,
      paymentMethod: order?.paymentOptions?.method ?? "",
      coupon: order?.orderSummary?.coupon ?? "",
      orderStatus: order?.orderStatus ?? "",
      action: order?._id,
    })) ?? [];

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [status, setStatus] = useState('pending')
  const [activeTab, setActiveTab] = useState(0); // To keep track of the active tab
  const toast = useToast();

  const createDeleteMutation = useDeleteOrdersMutation();

  const handleDelete = () => {
   
  };

  const handleConfirm = async () => {
    setIsModalVisible(false);
    if(isAction === 'approve'){
      const data = {
        orderStatus: status,
      }
    
        updateMutation.mutate(
          {
            apiUrl: `/api/order-status/${selectedIdEdit}`,
            data,
          },
          {
            onSuccess: () => {
              toast({
                title: "Order Status Updated successfully!",
                status: "success",
                duration: 3000,
              });
              setSelectedIdEdit(null)
    
              console.log('updated')
            },
            onError: (error) => {
              toast({
                title: error.response?.data?.details?.includes("duplicate")
                  ? "Order Status Already Exist"
                  : error.response?.data?.details ||
                    error.message ||
                    "Error Updating Order status",
                status: "error",
                duration: 3000,
              });
              setSelectedIdEdit(null)
              console.log(error.message)
            },
          },
        );
    }else{
      const data = {
        orderStatus: status,
      }
    
        updateMutation.mutate(
          {
            apiUrl: `/api/order-status/${selectedIdEdit}`,
            data,
          },
          {
            onSuccess: () => {
              toast({
                title: "Order Status Updated successfully!",
                status: "success",
                duration: 3000,
              });
              setSelectedIdEdit(null)
    
              console.log('updated')
            },
            onError: (error) => {
              toast({
                title: error.response?.data?.details?.includes("duplicate")
                  ? "Order Status Already Exist"
                  : error.response?.data?.details ||
                    error.message ||
                    "Error Updating Order status",
                status: "error",
                duration: 3000,
              });
              setSelectedIdEdit(null)
              console.log(error.message)
            },
          },
        );
    }
    
    
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  // useEffect(() => {
  //   if (selectedId) {
  //     handleDelete();
  //   }
    
  // }, [selectedId]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?.action);
      setStatus('success')
      setIsAction('approve')
      setIsModalVisible(true)
      setActionType('approve')
    };

    const handleDel = () => {
      // setSelectedId(props?.data?.action);
      setSelectedIdEdit(props?.data?.action);
      console.log(props?.data?.action)
      setStatus('delete')
      setActionType('delete')
      setIsAction('delete')
      setIsModalVisible(true)
    }
   

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={() => onRowClicked}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={handleDel}
        >
          <LiaTimesSolid />
        </button>

        <button onClick={handle}
        className="rounded-full bg-[green] p-1 text-white"
        >
        <MdCheck />
        </button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "_id",
      field: "_id",
      minWidth: 150,
      hide: true,
    },
    { headerName: "ID", field: "id", minWidth: 90 },
    { headerName: "First Name", field: "firstName", minWidth: 150 },
    { headerName: "Last Name", field: "lastName", minWidth: 150 },
    { headerName: "Cell Phone", field: "cellPhone", minWidth: 150 },
    { headerName: "Order Date", field: "orderDate", minWidth: 150 },
    { headerName: "Subtotal", field: "subtotal", minWidth: 150 },
    { headerName: "Discount", field: "discount", minWidth: 150 },
    { headerName: "Sales Tax", field: "salesTax", minWidth: 150 },
    { headerName: "Order Total", field: "orderTotal", minWidth: 150 },
    { headerName: "Order Status", field: "orderStatus", minWidth: 150 },
    { headerName: "Payment Method", field: "paymentMethod", minWidth: 150 },
    { headerName: "Coupon", field: "coupon", minWidth: 150 },
    { headerName: "Action", cellRenderer: btnComponent, minWidth: 150 },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  const items = [
    { label: "1st menu item", key: "1" },
    { label: "2nd menu item", key: "2" },
    { label: "3rd menu item", key: "3", danger: true },
    { label: "4th menu item", key: "4", danger: true, disabled: true },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const getFilteredOrders = (status) => {
    return transformOrders?.filter((order) => order.orderStatus === status);
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  console.log(transformOrders, "transformOrders");
  console.log(orders, "orders");

  const onRowClicked = (event) => {
    const rowData = event.data;
    const filterOrder = orders?.filter((item) => item._id === rowData._id);

    if (filterOrder?.length > 0) {
      const orderData = filterOrder[0];
      navigate(`/admin/dashboard/orders/${orderData?._id}`, {
        state: { orderData },
      });
    }
  };
  return (
    <div className="my-10">
      <Tabs onChange={(index) => setActiveTab(index)}>

      <TabPanel>
            <div
              className="ag-theme-quartz"
              style={{ height: 700, overflowX: "auto" }}
            >
              <AgGridReact
                modules={[ClientSideRowModelModule]}
                rowData={getFilteredOrders("pending")}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={[10, 25, 50]}
                // domLayout="autoHeight"
                // onRowClicked={onRowClicked}
              />
            </div>
          </TabPanel>
      </Tabs>

      <AntModal
        centered
        isOpen={isModalVisible}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title={
          actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
        }
        content={
          actionType === "approve"
            ? "Are you sure you want to approve this Order Status?"
            : "Are you sure you want to delete this Order?"
        }
        okText={"Confirm"}
      />

    </div>
  );
};

export default Pending;
