import React from 'react'
import InsuranceTables from './InsuranceTable'

const Enrolled = () => {
  return (
   <>
    <div>Enrolled</div>
    <InsuranceTables />
   </>
  )
}

export default Enrolled