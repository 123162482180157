import React from "react";

const BreederFooterInfo = () => {
  return (
    <div className="grid w-full grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 my-16">
      <div className="text-center px-4 sm:px-10 py-10 text-primaryBlack-theme">
        <p className="text-2xl font-medium text-primaryBlack-theme/50 tracking-wide uppercase">Puppies with a</p>
        <h6 className="text-5xl">guarantee</h6>
        <p className="py-8 font-medium leading-7">
          The health of your new family member is our primary concern. We take
          all necessary precautions from vet inspection of our pups to their
          parents requirement to pass annual exams. If there&apos;s something
          undiscoverable at 8 weeks of age that later comes up as hereditary or
          congenital, you&apos;re covered. We stand behind our pups with up to a 10
          Year guanrantee.
        </p>
      </div>
      <div className="text-center px-4 sm:px-10 py-10 text-primaryBlack-theme">
        <p className="text-2xl font-medium text-primaryBlack-theme/50 tracking-wide uppercase">Breeders with</p>
        <h6 className="text-5xl">Integrity</h6>
        <p className="py-8 font-medium leading-7">
          The health of your new family member is our primary concern. We take
          all necessary precautions from vet inspection of our pups to their
          parents requirement to pass annual exams. If there&apos;s something
          undiscoverable at 8 weeks of age that later comes up as hereditary or
          congenital, you&apos;re covered. We stand behind our pups with up to a 10
          Year guanrantee.
        </p>
      </div>
      <div className="text-center px-4 sm:px-10 py-10 text-primaryBlack-theme">
        <p className="text-2xl font-medium text-primaryBlack-theme/50 tracking-wide uppercase">Breeders with</p>
        <h6 className="text-5xl">Commitment</h6>
        <p className="py-8 font-medium leading-7">
          The health of your new family member is our primary concern. We take
          all necessary precautions from vet inspection of our pups to their
          parents requirement to pass annual exams. If there&apos;s something
          undiscoverable at 8 weeks of age that later comes up as hereditary or
          congenital, you&apos;re covered. We stand behind our pups with up to a 10
          Year guanrantee.
        </p>
      </div>
    </div>
  );
};

export default BreederFooterInfo;
