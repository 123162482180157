

import React from 'react'
import ChakraTabs from '../../components/ChakraTabs'
import Sales from './components/Sales'
import Pending from './components/Pending'
import Delete from './components/Delete'



const OrdersTabs = () => {
  const tabData = [
    {
        label: 'Sales',
        content: <Sales />,
    },
    {
        label: 'Pending',
        content: <Pending />,
    }, 
    {
        label: 'Delete',
        content: <Delete />
    }
  ]

  return (
    <div>
      <ChakraTabs tabData={tabData} /> 
    </div>
  )
}

export default OrdersTabs
