import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_PUPS } from "./pupsTypes";
import axios from "axios";
import { create, Delete, fetchAll, update } from "../Apis/Apis";

export const useAllPups = (apiUrl) => {
  return useQuery({
    queryKey: GET_ALL_PUPS,
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreatePups = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating Pups:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
  });
};

export const useDeletePupsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating pups:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
  });
};

export const useUpdatePupsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating pups:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_PUPS);
    },
  });
};
