import axios from "axios";

export const uploadFiles = async (files) => {
  if (!files) return null;
  const formData = new FormData();
  files.forEach((file) => {
    formData.append("files", file);
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/upload`,
      formData,
    );
    if (response?.data?.error) {
      console.log("File Upload Failed" + response?.data?.error);
    }

    const result = await response.data;
    return result.files.map((file) => file.fileUrl);
  } catch (error) {
    return error;
  }
};
