import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { login } from "../features/auth/authService"; // Adjust this path according to your project structure
import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../features/auth/actionTypes"; // Adjust this path according to your project structure
import {
  useForm,
  Resolver,
  SubmitHandler,
  Controller,
  Form,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const Signin = () => {
  // const [userEmail, setUserEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const dispatch = useDispatch();
  const { isUserAuth, isAdminAuth } = useSelector((state) => state.auth);
  const location = useLocation();

  const toast = useToast();
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
      password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character",
        )
        .required("Password is required"),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "verified") {
      toast({
        title: "Account Verified",
        description: "Your account has been successfully verified.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [location.search, toast]);

  const onSubmit = async (data) => {
    const { email, password } = data;
    setisLoading(true);

    try {
      const response = await login({ email: email, password });
      const data = response.data;

      dispatch({ type: LOGIN_SUCCESS, payload: data });

      if (data.role === "user" || data.role === "admin") {
        toast({
          title: "Authenticated",
          description: data.message || "You have successfully signed in.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        // setUserEmail("");
        // setPassword("");
        if (data.role === "user") {
          navigate("/");
        } else if (data.role === "admin") {
          navigate("/admin/watSchlist");
        }
      }
      setisLoading(false);
    } catch (error) {
      setisLoading(false);

      console.log(error, "errorerrorerror");
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "An error occurred during sign in.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isUserAuth) {
      navigate("/");
    } else if (isAdminAuth) {
      navigate("/admin/watchlist");
    }
  }, [isUserAuth, isAdminAuth, navigate]);

  if (isAdminAuth || isUserAuth) return;

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="grid w-full grid-cols-1 items-center justify-between md:grid-cols-2">
        <div className="hidden items-center justify-center md:flex">
          <img src="/images/logo.svg" alt="logo" width={400} />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex min-h-screen w-full flex-col items-center justify-center gap-6 bg-[#001219]"
        >
          <img
            src="/images/logo.svg"
            alt="logo"
            className="flex md:hidden"
            width={200}
          />

          <h1 className="text-3xl text-white">SignIn</h1>
          <div className="flex w-full flex-col items-center">
            <div className="w-[50%] py-4">
              <input
                type="email"
                placeholder="Enter email"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setUserEmail(e.target.value)}
                // disabled={isLoading}

                {...register("email")}
              />

              {errors?.email && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.email?.message}
                </p>
              )}
            </div>
            <div className="w-[50%] py-4">
              <input
                type="password"
                placeholder="Enter password"
                className="w-full rounded-lg p-3 outline-none"
                // onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
                {...register("password")}
              />
              {errors?.password && (
                <p
                  style={{
                    marginTop: "5px",
                    color: "red",
                    fontWeight: 600,
                  }}
                >
                  {errors?.password?.message}
                </p>
              )}
            </div>
          </div>

          <Link to="/password-reset" className="flex text-xs text-white">
            Forgot password?
          </Link>
          <Button
            isLoading={isLoading}
            loadingText="Submitting"
            w="50%"
            rounded="lg"
            bg="#0C96CC"
            color="white"
            p={3}
            type="submit"
            _hover={{ backgroundColor: "#0A82B6" }}
          >
            SignIn
          </Button>
          <p className="text-center text-sm text-gray-200">
            OR <br />
            <br />
            If you already have an account, click to {""}
            <Link
              to="/admin/signup"
              className="cursor-pointer font-semibold uppercase underline"
            >
              Signup
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Signin;
