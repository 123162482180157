import React from "react";

const PupDetails = () => {
  const imagesArr = [
    "/images/dog-breed.jpg",
    "/images/dog-breed.jpg",
    "/images/dog-breed.jpg",
    "/images/dog-breed.jpg",
    "/images/dog-breed.jpg",
  ];
  return (
    <div className="my-5">
      <div className="flex justify-between">
        <div className="flex items-center justify-center gap-5">
          <div className="h-40 w-40 overflow-hidden">
            <img
              src="/images/dog-breed.jpg"
              alt="breed"
              className="h-40 w-40 rounded-2xl object-cover"
            />
          </div>
          <div className="flex">
            <div className="flex w-full flex-col leading-6">
              <p className="text-lg font-bold text-[#DE0796]">GALE 6483</p>
              <p className="font-bold">Female - Shih Poo</p>
              <p>1lb 6oz @ 6.1 weeks</p>
              <p>Est Adult: 7-12 lbs</p>
              <br />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {imagesArr.map((image,index) => (
            <div key={index} className="h-20 w-20 overflow-hidden">
              <img
                src={image}
                alt="breed"
                className="h-20 w-20 rounded-2xl object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="my-5">
        <div className="mb-4">
          <h4 className="py-6 text-lg font-bold">Acquisition Info</h4>
          <div className="">
            <table className="w-full min-w-[300px] table-auto sm:min-w-[400px] lg:min-w-[600px]">
              <tbody>
                <tr>
                  <td>Puppy Adoption Fee</td>
                  <td>$ 3,299.00</td>
                </tr>
                <tr>
                  <td>Panacur Dewormer</td>
                  <td>$ 19.95</td>
                </tr>
                <tr>
                  <td>Probiotic Powder (2 Weeks)</td>
                  <td>$ 14.95</td>
                </tr>
                <tr>
                  <td>LostMyPet Microchip Enrollment</td>
                  <td>$ 19.95</td>
                </tr>
                <tr>
                  <td>Microchip Identification</td>
                  <td>$ 19.95</td>
                </tr>
                <tr>
                  <td className="py-6 font-bold">Sub Total</td>
                  <td className="py-6 font-bold">$ 2,206.50</td>
                </tr>
                <tr>
                  <td>Discount</td>
                  <td>$ 758.00</td>
                </tr>
                <tr>
                  <td>Sales Tax</td>
                  <td className="pb-4">$ 86.01</td>
                </tr>
                <tr>
                  <td>SAdditional Charges/Discounts</td>
                  <td className="py-4">$ $0.00</td>
                </tr>

                <tr>
                  <td className="font-bold">Order Total</td>
                  <td className="font-bold">$ 2,206.50</td>
                </tr>
                <tr>
                  <td>Payment Received</td>
                  <td>$ 758.00</td>
                </tr>
                <tr>
                  <td>Remaining Balance</td>
                  <td>$ 86.01</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PupDetails;
