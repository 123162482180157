import React from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Sales from "./components/Sales";
import BloodWood from "./components/BloodWood";
import Extras from "./components/Extras";
import Insurance from "./components/Insurance";
import ChakraTabs from "../../components/ChakraTabs";

const SalesDashboard = () => {
  const tabData = [
    {
      label: "Sales",
      content: <Sales />,
    },
    {
      label: "Insurance",
      content: <Insurance />,
    },
    {
      label: "Bloodwood",
      content: <BloodWood />,
    },
    {
      label: "Extras",
      content: <Extras />,
    },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default SalesDashboard;
