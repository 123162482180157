import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { GET_ALL_FAQS, GET_ALL_FAQS_CATEGORY } from "./faqsTypes.js";
import { create, Delete, fetchAll, update } from "../Apis/Apis";

export const useAllFAQS = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_FAQS],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateFAQS = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
  });
};

export const useDeleteFAQSMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
  });
};

export const useUpdateFAQSMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS);
    },
  });
};

export const useAllFAQS_CATEGORY = (apiUrl) => {
  return useQuery({
    queryKey: [GET_ALL_FAQS_CATEGORY],
    queryFn: () => fetchAll(apiUrl),
  });
};

export const useCreateFAQS_CATEGORY = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: create,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS CATEGORY:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
  });
};

export const useDeleteFAQS_CATEGORYMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Delete,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS CATEGORY:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
  });
};

export const useUpdateFAQS_CATEGORYMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: update,
    onMutate: async (newProduct) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onError: (err, newProduct, context) => {
      console.log("Error creating FAQS CATEGORY:", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
    onSettled: () => {
      queryClient.invalidateQueries(GET_ALL_FAQS_CATEGORY);
    },
  });
};
