import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import {
  useCreateWholeSales,
  useUpdateWholeSales,
} from "../../../features/wholesale/wholesalesService";
import dayjs from "dayjs";

const { TextArea } = Input;

const WholesalesForms = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateWholeSales();
  const UpdateMutation = useUpdateWholeSales();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        quantity: selectedData[0]?.quantity ?? null,
        transferDate: selectedData[0]?.transferDate
          ? dayjs(selectedData[0].transferDate)
          : null,
        purchaser: selectedData[0]?.purchaser ?? null,
        route: selectedData[0]?.route ?? null,
        deliveryMethod: selectedData[0]?.deliveryMethod ?? null,
        arrivalLocation: selectedData[0]?.arrivalLocation ?? null,
        flight: selectedData[0]?.flight ?? null,
        tracking: selectedData[0]?.tracking ?? null,
        dispositionDate: selectedData[0]?.dispositionDate
          ? dayjs(selectedData[0].dispositionDate)
          : null,
        airLines: selectedData[0]?.airLines ?? null,
        depart: selectedData[0]?.depart ?? null,
        status: selectedData[0]?.status ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      quantity: values.quantity ?? null,
      transferDate: values.transferDate ?? null,
      purchaser: values.purchaser ?? null,
      route: values.route ?? null,
      deliveryMethod: values.deliveryMethod ?? null,
      arrivalLocation: values?.arrivalLocation ?? null,
      flight: values?.flight ?? null,
      tracking: values?.tracking ?? null,
      dispositionDate: values?.dispositionDate ?? null,
      airLines: values?.airLines ?? null,
      depart: values?.depart ?? null,
      status: values?.status ?? null,
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/wholesale/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Wholesale Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/wholesale",
          data,
        });

        toast({
          title: "Wholesale created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Wholesale Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Wholesale",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">Wholesales</h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
           
          <Form.Item
                name="purchaser"
                label="Purchaser"
              >
                <Select
                  placeholder="Purchaser"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Capital City Pups", value: "Capital City Pups" },
                    { label: "Puppy Love Pr", value: "Puppy Love Pr" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>
            <div className="grid grid-cols-2 gap-5">
            <Form.Item
              name="quantity"
              label="Quantity"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="quantity" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="route"
              label="Route"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Route" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="deliveryMethod"
              label="Delivery Method"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Delivery Method" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="arrivalLocation"
              label="Arrival Location"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Arrival Location" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="flight"
              label="Flight"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Flight" disabled={isLoading} />
            </Form.Item>
            <Form.Item
              name="tracking"
              label="Tracking"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Tracking" disabled={isLoading} />
            </Form.Item>
            </div>
            <Form.Item
              name="airLines"
              label="Airlines"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Airlines" disabled={isLoading} />
            </Form.Item>
         
           
            
           
            <div className="grid grid-cols-2 gap-5">
           
              <Form.Item
                name="transferDate"
                label="Transfer Date"
                
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="dispositionDate"
                label="Disposition Date"
                
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <DatePicker />
              </Form.Item>
            </div>
            <Form.Item
              name="depart"
              label="Depart"
              
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Depart" disabled={isLoading} />
            </Form.Item>
            <Form.Item
                name="status"
                label="status"
              >
                <Select
                  placeholder="status"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "paid", value: "true" },
                    { label: "owe", value: "false" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>
          
           
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default WholesalesForms;
