import React, { useEffect, useMemo, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import PupsForm from "./PupsForm";
import PupsTable from "./PupsTable";
import { availablePups_tableData } from "../../../utils/data";
import {
  useAllPups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { getDate } from "../../../utils";

const Available = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updateMutation = useUpdatePupsMutation();
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [filters, setFilters] = useState({
    available: "",
    details: "",
    views: "",
    checkboxes: "",
    desc: "",
    wholesale: "",
  });
  const { data: puppies, isLoading, error } = useAllPups("/api/puppies");
  console.log("puppies===>", puppies);

  const handle = (id) => {
    setSelectedIdEdit(id);
    onOpen();
  };

  const handleUpdate = () => {
    const filter = puppies?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedIdEdit]);

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const filteredData = availablePups_tableData.filter(
    (item) =>
      item.available.includes(filters.available) &&
      item.details.toLowerCase().includes(filters.details.toLowerCase()) &&
      item.views.toLowerCase().includes(filters.views.toLowerCase()) &&
      item.checkboxes
        .toLowerCase()
        .includes(filters.checkboxes.toLowerCase()) &&
      item.desc.toLowerCase().includes(filters.desc.toLowerCase()) &&
      item.wholesale.toLowerCase().includes(filters.wholesale.toLowerCase()),
  );

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
        >
          Add Pups
        </button>

        <GlobalModalV1 onClose={onClose} isOpen={isOpen}>
          <PupsForm onClose={onClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <div className="my-10">
        <div className="rounded-md border border-gray-200">
          <div className="grid grid-cols-12 gap-4 p-4">
            <div className="col-span-5 grid grid-cols-2 gap-4">
              <div className="col-span-1 font-bold">Image</div>
              <div className="col-span-1 font-bold">
                <input
                  type="text"
                  placeholder="Details"
                  name="details"
                  value={filters.details}
                  onChange={handleFilterChange}
                  className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
                />
              </div>
            </div>
            <div className="col-span-1 font-bold">
              <input
                type="text"
                placeholder="Views"
                name="views"
                value={filters.views}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-1 font-bold">
              <input
                type="text"
                placeholder="Checkboxes"
                name="checkboxes"
                value={filters.checkboxes}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-3 font-bold">
              <input
                type="text"
                placeholder="Description"
                name="desc"
                value={filters.desc}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
            <div className="col-span-2 font-bold">
              <input
                type="text"
                placeholder="Wholesale"
                name="wholesale"
                value={filters.wholesale}
                onChange={handleFilterChange}
                className="w-full rounded border border-gray-300 bg-transparent p-2 font-medium outline-none"
              />
            </div>
          </div>

          {puppies &&
            puppies?.map((item, index) => (
              <div
                className="grid grid-cols-12 gap-4 border-t border-gray-200 p-4"
                key={index}
              >
                <div className="col-span-5 grid grid-cols-2 gap-4">
                  <div>
                    <img
                      src={
                        item?.listingInformation?.photos?.[0] ||
                        "default-image-url"
                      }
                      alt="breed-img"
                      className="h-auto max-w-full rounded-2xl"
                    />
                  </div>
                  <div>
                    <p>Available 
                    <p>{getDate(item.createdAt)}</p>
                    </p>
                    <p >
                      {item.listingInformation.name}
                      <span className="text-xl font-bold m-1">•</span>
                      {item.puppyInformation.cageNumber}
                    </p>
                    <p>
                      {item.listingInformation.heading}
                      <span className="text-xl font-bold ml-2">•</span>
                      {item.puppyInformation.idNumber}
                    </p>
                    <p>
                      {item.puppyInformation?.breed?.breedInformation.breedName}
                    </p>
                    <p>
                      {item.puppyInformation.gender}
                      <span className="text-xl font-bold m-1">•</span>
                      {item.puppyInformation.registration}
                      <span className="text-xl font-bold m-1">•</span>
                      {item.puppyInformation.weight} lb
                    </p>
                    <p>
                      {getDate(item.puppyInformation.dateAcquired)}
                      <span className="text-xl font-bold m-1">•</span>
                      {item.listingInformation.title}
                      <span className="text-xl font-bold m-1">•</span>
                      {item.listingInformation.adultWeight}
                    </p>
                    <p>{item.puppyInformation.cost}</p>

                    <br />

                    <div className="mt-2 flex space-x-2">
                      <button
                        onClick={() => handle(item._id)}
                        className="rounded bg-blue-500 px-2 py-1 text-sm text-white"
                      >
                        Edit
                      </button>
                      <button className="rounded bg-green-500 px-2 py-1 text-sm text-white">
                        Redirect
                      </button>
                      <button className="rounded bg-yellow-500 px-2 py-1 text-sm text-white">
                        Special Request
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <p>{item.listingInformation.offerPrice}</p>
                </div>
                <div className="col-span-1">
                  <div className="flex items-center">
                    <span>Feature:</span>
                    <input
                      type="checkbox"
                      checked={item.listingInformation.feature}
                      readOnly
                      className="ml-2"
                    />
                  </div>
                  <div className="mt-2 flex items-center">
                    <span>DNL:</span>
                    <input
                      type="checkbox"
                      checked={item.listingInformation.doNotList}
                      readOnly
                      className="ml-2"
                    />
                  </div>
                </div>

                <div className="col-span-3">
                  {item.listingInformation.description}
                </div>
                <div className="col-span-2">
                  <p>{item.listingInformation.price}</p>
                  <p>{item.puppyInformation.purchaser}</p>
                  <p>{item.listingInformation.quantity}</p>
                  <p>{getDate(item.puppyInformation.wholesaleTransferDate)}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <PupsTable /> */}
      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <PupsForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default Available;
