import React from "react";
import BreederFooterInfo from "../components/BreederFooterInfo";
// import { teams } from "../utils/data";
import { useAllPages } from "../features/Page/pageServices";
import { useAllTEAMS } from "../features/Teams/teamsService";

const Teams = () => {
  const {data: page, error, isLoading} = useAllPages("/api/pages")
  console.log("Page data===>",page)

  const {data: teams, teamError, teamIsLoading} = useAllTEAMS("/api/teams");
  console.log("teams===>", teams);

  return (
    <div className="poppins-regular relative w-full">
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat "
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>
      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-56 md:px-32 ">
        <h6 className="text-3xl md:text-5xl">Our Team</h6>
        <h3 className="my-6 text-2xl font-bold">MEET OUR FAMILY</h3>
        <div className="my-12 flex w-full max-w-5xl flex-col mt-36">
          {teams &&
            teams.map((team) => (
              <div
                key={team._id}
                className="my-8 flex flex-col items-center justify-between gap-8 md:flex-row "
              >
                {/* Image Section */}
                <div className="h-[400px] w-full rounded-3xl bg-center md:h-[620px] md:w-[510px] md:rounded-[80px]">
                  <img
                    src={team.media[0]}
                    alt="team"
                    className="h-[90%] w-[90%] rounded-3xl object-cover md:rounded-[80px]"
                  />
                </div>

                {/* Content Section */}
                <div className="flex w-full flex-col justify-around space-y-4 md:w-auto md:max-w-[500px]">
                  {/* Name and Designation */}
                  <div>
                    <p className="text-2xl font-semibold">{team?.name}</p>
                    <p>{team?.title}</p>
                  </div>

                  {/* Details */}
                  <div>
                    <p>{team?.description}</p>
                  </div>

                  {/* Favorite Breeds */}
                  {team?.favoriteBreed?.length > 0 && (
                    <div>
                      <p className="font-semibold">
                        Favorite Breeds <br />
                        <span className="font-normal">
                          {team.favoriteBreed?.map((breeds, index) => (
                            <span key={breeds} className="">
                              {breeds}
                              {index < team?.favoriteBreed?.length - 1 && " | "}
                            </span>
                          ))}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <BreederFooterInfo />
      </div>
    </div>
  );
};

export default Teams;
