import React from "react";
import { Link } from "react-router-dom";

const OrderConfirmedPage = () => {
  return (
    <div className="font-roboto mx-auto mt-[200px] h-[500px] max-w-[480px] text-center text-[18px] leading-[24px]">
      <h1 className="my-10 text-3xl font-bold">Thank You For Your Purchase!</h1>
      <div className="relative mb-8 inline-block">
        <div
          className="relative z-10 h-[152px] w-[152px] bg-cover bg-center"
          style={{ backgroundImage: "url('/images/check-confetti.svg')" }}
        ></div>
        <div
          className="absolute left-0 top-0 h-[152px] w-[152px] bg-center bg-no-repeat"
          style={{ backgroundImage: "url('/images/check.svg')" }}
        ></div>
      </div>

      <p>
        <strong>Your order details has been recorded</strong>
      </p>

      <p>
        Our team is will get back to you shortly for further order confirmation.
      </p>
      <p>You'll hear from us soon.</p>
      <div className="mt-6">
        <Link to="/" className="font-semibold text-blue-600">
          Go to home page
        </Link>
      </div>
    </div>
  );
};

export default OrderConfirmedPage;
