import React, { useRef, useState } from "react";

import { GlobalModalV1 } from "../../components/GlobalModal";
import TeamsForm from "./components/TeamsForm";
import { useDisclosure } from "@chakra-ui/react";
import TableTeams from "./components/TableTeams";

const Teams = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="mt-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        + Add New Member
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"40%"}>
        <TeamsForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <TableTeams />
    </>
  );
};

export default Teams;
