import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Radio, Select, Upload } from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../../utils/uploadFile";
import {
  useCreateBreeds,
  useUpdateBreedMutation,
} from "../../../../features/Breeds/Breeds";
import { CiSquareRemove } from "react-icons/ci";
import { useAllCategory } from "../../../../features/Category/Category";

const { TextArea } = Input;

const BreedForm = ({ onClose, selectedData, isUpdateModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { data: categories } = useAllCategory("/api/categories/");

  const createMutation = useCreateBreeds(); // Initialize the mutation
  const UpdateMutation = useUpdateBreedMutation(); // Initialize the mutation

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [primaryPhoto, setPrimaryPhoto] = useState([]);
  const [characteristicsPhoto, setCharacteristicsPhoto] = useState([]);
  const [avatarPhoto, setAvatarPhoto] = useState([]);
  const [coverSales, setCoverSales] = useState([]);
  const [coverInfo, setCoverInfo] = useState([]);
  const [breedPhoto, setBreedPhoto] = useState([]);
  const [video, setVideo] = useState([]);
  const [showFAQS, setShowFAQS] = useState(false);
  const [FAQS, setFAQS] = useState([]);
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();

  useEffect(() => {
    console.log(selectedData, "selectedData");

    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        breedInformation: {
          breedName: selectedData[0]?.breedInformation?.breedName,
          category: selectedData[0]?.breedInformation?.category.name,
          petPlanBreedId: selectedData[0]?.breedInformation?.petPlanBreedId,
          alternateNames: selectedData[0]?.breedInformation?.alternateNames,
          safeAlternateNames:
            selectedData[0]?.breedInformation?.safeAlternateNames,
          relatedBreeds: selectedData[0]?.breedInformation?.relatedBreeds,
          isActive: selectedData[0]?.breedInformation?.isActive,
          primaryPhotoAlt: selectedData[0]?.breedInformation?.primaryPhoto?.alt,
          primaryPhotoTitle:
            selectedData[0]?.breedInformation?.primaryPhoto?.title,
          characteristicsPhotoAlt:
            selectedData[0]?.breedInformation?.characteristicsPhoto?.alt,
          characteristicsPhotoTitle:
            selectedData[0]?.breedInformation?.characteristicsPhoto?.title,
          avatarAlt: selectedData[0]?.breedInformation?.avatarPhoto?.alt,
          avatarTitle: selectedData[0]?.breedInformation?.avatarPhoto?.title,
          coverSalesAlt: selectedData[0]?.breedInformation?.coverSales?.alt,
          coverSalesTitle: selectedData[0]?.breedInformation?.coverSales?.title,
          coverInfoAlt: selectedData[0]?.breedInformation?.coverInfo?.alt,
          coverInfoTitle: selectedData[0]?.breedInformation?.coverInfo?.title,
          locationsCityInfo:
            selectedData[0]?.breedInformation?.locationsCityInfo,
          breedCityInfo: selectedData[0]?.breedInformation?.breedCityInfo,
          breederCityInfo: selectedData[0]?.breedInformation?.breederCityInfo,
        },
        breedDetails: {
          slug: selectedData[0]?.breedDetails?.slug,
          pageTitle: selectedData[0]?.breedDetails?.pageTitle,
          metaDescription: selectedData[0]?.breedDetails?.metaDescription,
          heading: selectedData[0]?.breedDetails?.heading,
          breedTypeMix: selectedData[0]?.breedDetails?.breedTypeMix,
          energyLevel: selectedData[0]?.breedDetails?.energyLevel,
          shedding: selectedData[0]?.breedDetails?.shedding,
          training: selectedData[0]?.breedDetails?.training,
          temperament: selectedData[0]?.breedDetails?.temperament,
          adultWeight: selectedData[0]?.breedDetails?.adultWeight,
          adultHeight: selectedData[0]?.breedDetails?.adultHeight,
          lifespan: selectedData[0]?.breedDetails?.lifespan,
          compareCaption: selectedData[0]?.breedDetails?.compareCaption,
          overview: selectedData[0]?.breedDetails?.overview,
          characteristics: selectedData[0]?.breedDetails?.characteristics,
          breeders: selectedData[0]?.breedDetails?.breeders,
          appearance: selectedData[0]?.breedDetails?.appearance,
          temperamentDetails: selectedData[0]?.breedDetails?.temperamentDetails,
          insights: selectedData[0]?.breedDetails?.insights,
          history: selectedData[0]?.breedDetails?.history,
          care: selectedData[0]?.breedDetails?.care,
          breedSalesDetails: selectedData[0]?.breedDetails?.breedSalesDetails,
          seoDetails: selectedData[0]?.breedDetails?.seoDetails,
        },
      });
    }
  }, [selectedData, isUpdateModal]);

  const handleClickFAQS = () => {
    setFAQS((prev) => [...prev, { question: question, answer, answer }]);
    setQuestion(null);
    setAnswer(null);
    setShowFAQS(false);
  };

  const handleDeleteFAQS = (id) => {
    const filter = FAQS.filter((data, index) => index !== id);
    setFAQS(filter);
    setQuestion(null);
    setAnswer(null);
    setShowFAQS(false);
  };

  console.log(FAQS, "FAQS");

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    console.log(values, "values");

    const { breedInformation, breedDetails } = values;

    // Prepare data object
    const data = {
      breedInformation: {
        breedName: breedInformation?.breedName ?? null,
        category: breedInformation?.category ?? null,
        petPlanBreedId: breedInformation?.petPlanBreedId ?? null,
        alternateNames: breedInformation?.alternateNames ?? null,
        safeAlternateNames: breedInformation?.safeAlternateNames ?? null,
        relatedBreeds: breedInformation?.relatedBreeds ?? null,
        isActive: breedInformation?.isActive ?? null,
        primaryPhoto: {
          url: primaryPhoto?.length ? primaryPhoto[0] : null,
          alt: breedInformation?.primaryPhotoAlt ?? null,
          title: breedInformation?.primaryPhotoTitle ?? null,
        },
        characteristicsPhoto: {
          url: characteristicsPhoto?.length ? characteristicsPhoto[0] : null,
          alt: breedInformation?.characteristicsPhotoAlt ?? null,
          title: breedInformation?.characteristicsPhotoTitle ?? null,
        },
        avatarPhoto: {
          url: avatarPhoto?.length ? avatarPhoto[0] : null,
          alt: breedInformation?.avatarAlt ?? null,
          title: breedInformation?.avatarTitle ?? null,
        },
        coverSales: {
          url: coverSales?.length ? coverSales[0] : null,
          alt: breedInformation?.coverSalesAlt ?? null,
          title: breedInformation?.coverSalesTitle ?? null,
        },
        coverInfo: {
          url: coverInfo?.length ? coverInfo[0] : null,
          alt: breedInformation?.coverInfoAlt ?? null,
          title: breedInformation?.coverInfoTitle ?? null,
        },
        breedPhotos: breedPhoto?.length ? breedPhoto : null,
        locationsCityInfo: breedInformation?.locationsCityInfo,
        breedCityInfo: breedInformation?.breedCityInfo,
        breederCityInfo: breedInformation?.breederCityInfo,
        frequentlyAskedQuestions: FAQS?.length ? FAQS : null,
      },
      breedDetails: {
        slug: breedDetails?.slug ?? null,
        pageTitle: breedDetails?.pageTitle ?? null,
        metaDescription: breedDetails?.metaDescription ?? null,
        heading: breedDetails?.heading ?? null,
        breedTypeMix: breedDetails?.breedTypeMix ?? null,
        energyLevel: breedDetails?.energyLevel ?? null,
        shedding: breedDetails?.shedding ?? null,
        training: breedDetails?.training ?? null,
        temperament: breedDetails?.temperament ?? null,
        adultWeight: breedDetails?.adultWeight ?? null,
        adultHeight: breedDetails?.adultHeight ?? null,
        lifespan: breedDetails?.lifespan ?? null,
        compareCaption: breedDetails?.compareCaption ?? null,
        overview: breedDetails?.overview ?? null,
        characteristics: breedDetails?.characteristics ?? null,
        breeders: breedDetails?.breeders ?? null,
        appearance: breedDetails?.appearance ?? null,
        temperamentDetails: breedDetails?.temperamentDetails ?? null,
        insights: breedDetails?.insights ?? null,
        history: breedDetails?.history ?? null,
        care: breedDetails?.care ?? null,
        video: video?.length ? video[0] : null,
        breedSalesDetails: breedDetails?.breedSalesDetails ?? null,
        seoDetails: breedDetails.seoDetails,
      },
    };
    if (primaryPhoto) {
      const primaryPhotoUrls = await uploadFiles(primaryPhoto);
      data.breedInformation.primaryPhoto.url = primaryPhotoUrls.length
        ? primaryPhotoUrls[0]
        : null;
    }

    if (characteristicsPhoto) {
      const characteristicsPhotoUrls = await uploadFiles(characteristicsPhoto);
      data.breedInformation.characteristicsPhoto.url =
        characteristicsPhotoUrls.length ? characteristicsPhotoUrls[0] : null;
    }

    if (avatarPhoto) {
      const avatarPhotoUrls = await uploadFiles(avatarPhoto);
      data.breedInformation.avatarPhoto.url = avatarPhotoUrls.length
        ? avatarPhotoUrls[0]
        : null;
    }

    if (coverSales) {
      const coverSalesUrls = await uploadFiles(coverSales);
      data.breedInformation.coverSales.url = coverSalesUrls.length
        ? coverSalesUrls[0]
        : null;
    }

    if (coverInfo) {
      const coverInfoUrls = await uploadFiles(coverInfo);
      data.breedInformation.coverInfo.url = coverInfoUrls.length
        ? coverInfoUrls[0]
        : null;
    }

    if (breedPhoto) {
      const breedPhotoUrls = await uploadFiles(breedPhoto);
      data.breedInformation.breedPhotos = breedPhotoUrls.length
        ? breedPhotoUrls
        : null;
    }

    if (video) {
      const videoUrls = await uploadFiles(video);
      data.breedDetails.video = videoUrls.length ? videoUrls[0] : null;
    }

    if (isUpdateModal && selectedData) {
      UpdateMutation.mutate(
        {
          apiUrl: `/api/breed/${selectedData[0]?._id}`,
          data,
        },
        {
          onSuccess: () => {
            setFileList([]);
            setPrimaryPhoto([]);
            setCharacteristicsPhoto([]);
            setAvatarPhoto([]);
            setCoverSales([]);
            setCoverInfo([]);
            setBreedPhoto([]);
            setVideo([]);
            setFAQS([]);
            setQuestion("");
            setAnswer("");
            form.resetFields();
            toast({
              title: "Breed Updated successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });

            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Breed Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating Breed",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          apiUrl: "/api/breed",
          data,
        },
        {
          onSuccess: () => {
            setFileList([]);
            setPrimaryPhoto([]);
            setCharacteristicsPhoto([]);
            setAvatarPhoto([]);
            setCoverSales([]);
            setCoverInfo([]);
            setBreedPhoto([]);
            setVideo([]);
            setFAQS([]);
            setQuestion("");
            setAnswer("");
            form.resetFields();
            toast({
              title: "Breed created successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });

            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Breed Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error creating Breed",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }

    setIsLoading(false);
  };

  const breedOptions = categories?.map((category) => ({
    value: category._id,
    label: category?.name,
  }));

  return (
    <div className="auto mx-0 mt-10 min-h-screen overflow-y-auto">
      <Form
        form={form}
        // wrapperCol={{ span:  }}
        layout="horizontal"
        className="m-auto flex w-[90%] flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Breed Details
        </h1>
        <div className="grid m-auto w-[80%] grid-cols-2 gap-20 pt-10">
          <div className="w-full">
            <div>
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breed Information
              </div>
              <Form.Item
                name={["breedInformation", "breedName"]}
                label="Breed Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "category"]}
                label="Category"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a breed"
                  style={{ width: "100%" }}
                  allowClear
                  options={breedOptions}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "petPlanBreedId"]}
                label="Pet Plan Breed ID"
              >
                <Input placeholder="12345" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "alternateNames"]}
                label="Alternate Names"
              >
                <Input placeholder="Goldie" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "safeAlternateNames"]}
                label="Safe Alternate Names"
              >
                <Input placeholder="Golden" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "relatedBreeds"]}
                label="Related Breeds"
              >
                <Input placeholder="Labrador Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "isActive"]}
                label="Active"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Primary Photo{" "}
              </div>

              <Form.Item
                label="Video"
                name={["breedInformation", "primaryPhotos"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={primaryPhoto}
                  beforeUpload={(file) => {
                    setPrimaryPhoto((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setPrimaryPhoto((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {primaryPhoto?.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "primaryPhotoAlt"]}
                label="Primary Photo Alt"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "primaryPhotoTitle"]}
                label="Primary Photo Title"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Characteristics Photo
              </div>

              <Form.Item
                label="Characteristics Photo"
                name={["breedInformation", "characteristicsPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={characteristicsPhoto}
                  beforeUpload={(file) => {
                    setCharacteristicsPhoto((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setCharacteristicsPhoto((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {characteristicsPhoto?.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "characteristicsPhotoAlt"]}
                label="Characteristics Photo Alt"
              >
                <Input
                  placeholder="Golden Retriever Characteristics"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "characteristicsPhotoTitle"]}
                label="Characteristics Photo Title"
              >
                <Input
                  placeholder="Golden Retriever Characteristics"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Avatar Photo
              </div>

              <Form.Item
                label="Avatar Photo"
                name={["breedInformation", "avatarPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={avatarPhoto}
                  beforeUpload={(file) => {
                    setAvatarPhoto((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setAvatarPhoto((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {avatarPhoto?.length === 0 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "avatarAlt"]}
                label="Avatar Photo Alt"
              >
                <Input
                  placeholder="Golden Retriever Avatar"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "avatarTitle"]}
                label="Avatar Photo Title"
              >
                <Input
                  placeholder="Golden Retriever Avatar"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Cover Sales
              </div>

              <Form.Item
                label="Cover Sales"
                name={["breedInformation", "coverSales"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={coverSales}
                  beforeUpload={(file) => {
                    setCoverSales((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setCoverSales((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {coverSales?.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "coverSalesAlt"]}
                label="Cover Sales Alt"
              >
                <Input
                  placeholder="Golden Retriever Sales"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "coverSalesTitle"]}
                label="Cover Sales Title"
              >
                <Input
                  placeholder="Golden Retriever Sales"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Cover Info
              </div>

              <Form.Item
                label="Cover Info"
                name={["breedInformation", "coverInfo"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={coverInfo}
                  beforeUpload={(file) => {
                    setCoverInfo((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setCoverInfo((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {coverInfo?.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "coverInfoAlt"]}
                label="Cover Info Alt"
              >
                <Input
                  placeholder="Golden Retriever Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "coverInfoTitle"]}
                label="Cover Info Title"
              >
                <Input
                  placeholder="Golden Retriever Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breed Photo
              </div>

              <Form.Item
                label="Breed Photo"
                name={["breedInformation", "breedPhoto"]}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  listType="picture-card"
                  fileList={breedPhoto}
                  beforeUpload={(file) => {
                    setBreedPhoto((prev) => [...prev, file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setBreedPhoto((prev) =>
                      prev.filter((f) => f.uid !== file.uid),
                    );
                  }}
                  multiple={true}
                  disabled={isLoading}
                >
                  {breedPhoto?.length < 5 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breedPhotoAlt"]}
                label="Breed Photo Alt"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breedPhotoTitle"]}
                label="Breed Photo Title"
              >
                <Input placeholder="Golden Retriever" disabled={isLoading} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                FAQ&apos;s
              </div>

              {FAQS?.length > 0 &&
                FAQS.map((faq, index) => (
                  <div
                    key={index}
                    className="mb-2 flex w-full items-center justify-between rounded-md bg-gray-100 p-2"
                  >
                    <div>
                      <div className="font-semibold">{faq.question}</div>
                      <div className="text-gray-500">{faq.answer}</div>
                    </div>
                    <Button
                      type="link"
                      danger
                      onClick={() => handleDeleteFAQS(index)}
                      disabled={isLoading}
                    >
                      Delete
                    </Button>
                  </div>
                ))}

              <Button
                type="dashed"
                onClick={() => setShowFAQS(!showFAQS)}
                disabled={isLoading}
                className="mb-3 w-full"
              >
                {showFAQS ? "Hide FAQs Form" : "Add FAQs"}
              </Button>

              {showFAQS && (
                <div className="mb-4 w-full rounded-md bg-gray-100 p-4">
                  <Form.Item label="Question" name="question">
                    <Input
                      value={question}
                      onChange={(e) => setQuestion(e.target.value)}
                      disabled={isLoading}
                    />
                  </Form.Item>

                  <Form.Item label="Answer" name="answer">
                    <TextArea
                      rows={4}
                      value={answer}
                      onChange={(e) => setAnswer(e.target.value)}
                      disabled={isLoading}
                    />
                  </Form.Item>

                  <Button
                    type="primary"
                    onClick={handleClickFAQS}
                    disabled={!question || !answer || isLoading}
                    className="w-full"
                  >
                    Add FAQ
                  </Button>
                </div>
              )}

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Locations City Info
              </div>
              {/* Locations City Info */}
              <Form.Item
                name={["breedInformation", "locationsCityInfo", "title"]}
                label="Locations City Info Title"
              >
                <Input
                  placeholder="Golden Retriever in City"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={[
                  "breedInformation",
                  "locationsCityInfo",
                  "metaDescription",
                ]}
                label="Locations City Info Meta Description"
              >
                <TextArea
                  placeholder="Information about Golden Retrievers in the city"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "locationsCityInfo", "heading"]}
                label="Locations City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever City Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "locationsCityInfo", "description"]}
                label="Locations City Info Description"
              >
                <TextArea
                  placeholder="Golden Retrievers are popular in urban areas."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breeder City Info
              </div>

              {/* Breeder City Info */}
              <Form.Item
                name={["breedInformation", "breederCityInfo", "title"]}
                label="Breeder City Info Title"
              >
                <Input
                  placeholder="Golden Retriever Breeders"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={[
                  "breedInformation",
                  "breederCityInfo",
                  "metaDescription",
                ]}
                label="Breeder City Info Meta Description"
              >
                <TextArea
                  placeholder="Find Golden Retriever breeders"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breederCityInfo", "heading"]}
                label="Breeder City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever Breeders Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breederCityInfo", "description"]}
                label="Breeder City Info Description"
              >
                <TextArea
                  placeholder="List of reputable Golden Retriever breeders."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Breeder City Info
              </div>
              {/* Breed City Info */}
              <Form.Item
                name={["breedInformation", "breedCityInfo", "title"]}
                label="Breed City Info Title"
              >
                <Input
                  placeholder="Golden Retriever City Info"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breedCityInfo", "metaDescription"]}
                label="Breed City Info Meta Description"
              >
                <TextArea
                  placeholder="Information about Golden Retrievers in different cities"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breedCityInfo", "heading"]}
                label="Breed City Info Heading"
              >
                <Input
                  placeholder="Golden Retriever City Information"
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["breedInformation", "breedCityInfo", "description"]}
                label="Breed City Info Description"
              >
                <TextArea
                  placeholder="Golden Retrievers are adaptable to various urban settings."
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>
            </div>
          </div>

          <div className="w-full">
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Breed Details
            </div>
            {/* Breed Details */}

            <Form.Item
              name={["breedDetails", "slug"]}
              label="Slug"
              rules={[{ required: true }]}
            >
              <Input placeholder="golden-retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "pageTitle"]}
              label="Page Title"
              rules={[{ required: true }]}
            >
              <Input placeholder="Golden Retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "metaDescription"]}
              label="Meta Description"
              rules={[{ required: true }]}
            >
              <TextArea
                placeholder="Learn more about Golden Retrievers"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "heading"]} label="Heading">
              <Input placeholder="Golden Retriever" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedTypeMix"]}
              label="Breed Type Mix"
            >
              <Input placeholder="Purebred" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "energyLevel"]}
              label="Energy Level"
            >
              <Input placeholder="High" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["breedDetails", "shedding"]} label="Shedding">
              <Input placeholder="Moderate" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["breedDetails", "training"]} label="Training">
              <Input placeholder="Easy" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "temperament"]}
              label="Temperament"
            >
              <Input
                placeholder="Friendly, Intelligent, Devoted"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "adultWeight"]}
              label="Adult Weight"
            >
              <Input placeholder="55-75 lbs" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "adultHeight"]}
              label="Adult Height"
            >
              <Input placeholder="21.5-24 inches" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["breedDetails", "lifespan"]} label="Lifespan">
              <Input placeholder="10-12 years" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "compareCaption"]}
              label="Compare Caption"
            >
              <Input
                placeholder="Compare Golden Retrievers"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "overview"]} label="Overview">
              <TextArea
                placeholder="Golden Retrievers are known for their friendly and tolerant attitudes."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "characteristics"]}
              label="Characteristics"
            >
              <TextArea
                placeholder="Golden Retrievers are known for their loyal and friendly nature."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "breeders"]} label="Breeders">
              <TextArea
                placeholder="Find reputable Golden Retriever breeders."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "appearance"]} label="Appearance">
              <TextArea
                placeholder="Golden Retrievers have a dense, water-repellant outer coat with a thick undercoat."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "temperamentDetails"]}
              label="Temperament Details"
            >
              <TextArea
                placeholder="Golden Retrievers are friendly, intelligent, and devoted."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "insights"]} label="Insights">
              <TextArea
                placeholder="Golden Retrievers are often used in search and rescue operations."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "history"]} label="History">
              <TextArea
                placeholder="Golden Retrievers were originally bred in Scotland in the mid-19th century."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item name={["breedDetails", "care"]} label="Care">
              <TextArea
                placeholder="Golden Retrievers need regular grooming and exercise."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              label="Video "
              name={["breedDetails", "video"]}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture-card"
                fileList={video}
                beforeUpload={(file) => {
                  setVideo((prev) => [...prev, file]);
                  return false; // Prevent automatic upload
                }}
                onRemove={(file) => {
                  setVideo(video.filter((f) => f.uid !== file.uid));
                }}
                multiple={true} // Ensure only one file can be uploaded
                disabled={isLoading}
              >
                {video?.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Breed Sales Details
            </div>

            {/* Breed Sales Details */}
            <Form.Item
              name={["breedDetails", "breedSalesDetails", "title"]}
              label="Sales Details Title"
            >
              <Input
                placeholder="Golden Retriever Puppies for Sale"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "metaDescription"]}
              label="Sales Details Meta Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "heading"]}
              label="Sales Details Heading"
            >
              <Input
                placeholder="Golden Retriever Puppies"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "subheading"]}
              label="Sales Details Subheading"
            >
              <Input
                placeholder="Find your perfect Golden Retriever puppy"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "topDescription"]}
              label="Sales Details Top Description"
            >
              <TextArea
                placeholder="Golden Retrievers are friendly and easy to train."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "breedDescription"]}
              label="Sales Details Breed Description"
            >
              <TextArea
                placeholder="Golden Retrievers make excellent family pets."
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "maintenance"]}
              label="Sales Details Maintenance"
            >
              <Input placeholder="4" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "training"]}
              label="Sales Details Training"
            >
              <Input placeholder="5" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "shedding"]}
              label="Sales Details Shedding"
            >
              <Input placeholder="3" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "adaptability"]}
              label="Sales Details Adaptability"
            >
              <Input placeholder="4" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "breedSalesDetails", "active"]}
              label="Sales Details Active"
            >
              <Input placeholder="5" disabled={isLoading} />
            </Form.Item>

            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              SEO
            </div>

            <Form.Item
              name={["breedDetails", "seoDetails", "title"]}
              label=" Title"
            >
              <Input
                placeholder="Golden Retriever Puppies for Sale"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "seoDetails", "metadescription"]}
              label="Meta Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "seoDetails", "heading"]}
              label="Heading"
            >
              <Input
                placeholder="Golden Retriever Puppies"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["breedDetails", "seoDetails", "description"]}
              label="Description"
            >
              <TextArea
                placeholder="Find Golden Retriever puppies for sale"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default BreedForm;
