import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllTEAMS,
  useDeleteTEAMSMutation,
  useUpdateTEAMSMutation,
} from "../../../features/Teams/teamsService";
import { useDisclosure, useToast } from "@chakra-ui/react";
import TeamsForm from "./TeamsForm";
import { GlobalModalV1 } from "../../../components/GlobalModal";

const TableTeams = () => {
  const [showPage, setShowPage] = useState(false);
  const { data: teams, isLoading, error } = useAllTEAMS("/api/teams");
  console.log('teams', teams)
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const pageHandler = () => {
    setShowPage(!showPage);
  };

  const updateMutation = useUpdateTEAMSMutation();
  const deleteMutation = useDeleteTEAMSMutation();

  const btnComponent = (props) => {
    const handleEdit = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handleEdit}
        >
          <AiFillEdit />
        </button>
        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => setSelectedId(props?.data?._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const handleDelete = () => {
    deleteMutation.mutate(
      { apiUrl: `/api/team/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "TEAM deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: () => {
          toast({
            title: "Error deleting TEAM",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = teams?.filter((item) => item?._id === selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  const columns = [
    { headerName: "Name", field: "name", editable: true }, // Maps to 'name'
    { headerName: "Title", field: "title", editable: true }, // Maps to 'title'
    { headerName: "Description", field: "description", editable: true }, // Maps to 'description'
    { headerName: "Quote", field: "quote", editable: true }, // Maps to 'quote'
    { headerName: "Author Block", field: "authorBlock", editable: true }, // Maps to 'authorBlock'
    // { headerName: "Primary Photo", field: "primaryPhoto", editable: true }, // Maps to 'primaryPhoto'
    { headerName: "Display Order", field: "displayOrder", editable: true }, // Maps to 'displayOrder'
    {headerName: "Favorites breeds", field: 'favoriteBreed'},
    {
      headerName: "Active",
      field: "active",
      editable: true,
    }, // Maps to 'active'
    {
      headerName: "Show On Team Page",
      field: "showOnTeamPage",
      editable: true,
    }, // Maps to 'showOnTeamPage'
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div className="my-5">
      <div
        className="ag-theme-quartz"
        style={{ height: 500, overflowX: "auto" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={teams}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <TeamsForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default TableTeams;
