import React from 'react'
import Table from '../../../components/Table'
import Table3 from '../../../components/Table3'

const RejectedOffers = () => {
  return (
    <div>
      <Table3 />
    </div>
  )
}

export default RejectedOffers