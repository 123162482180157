import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllTargetCities,
  useDeleteTargetCities,
} from "../../../features/TargetCities/targetCitiesServices";
import { useDisclosure, useToast } from "@chakra-ui/react";
import TargetCitiesForm from "./TargetCitiesForm";
import { GlobalModalV1 } from "../../../components/GlobalModal";

const TargetCitiesTable = () => {
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    data: targetCities,
    error,
    isLoading,
  } = useAllTargetCities("/api/target-cities");
  console.log("targetCitest===>", targetCities);
  const deleteMutation = useDeleteTargetCities();

  const transformTargetCities =
    targetCities?.map((targetCity, index) => ({
      _id: targetCity?._id ?? "",
      id: targetCity?.id ?? "",
      name: targetCity?.name ?? "",
      state: targetCity?.state ?? "",
      latitude: targetCity?.latitude ?? "",
      longitude: targetCity?.longitude ?? "",
      created: targetCity?.created ?? "",
    })) ?? [];

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const handleDelete = () => {
    deleteMutation.mutate(
      { apiUrl: `/api/target-city/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Target Cities Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Target City");
          toast({
            title: "Error deleted Target City",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = targetCities?.filter(
      (item) => item?._id === selectedIdEdit,
    );
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <button onClick={handle} className="rounded-full bg-[#35f8fe] p-1 text-white">
          <AiFillEdit />
        </button>

        <button
          onClick={() => setSelectedId(props?.data?._id)}
          className="rounded-full bg-[#EE5D50] p-1 text-white"
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const columns = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "State",
      field: "state",
    },
    {
      headerName: "Latitude",
      field: "latitude",
      valueFormatter: (params) => params.value?.toFixed(7),
    },
    {
      headerName: "Longitude",
      field: "longitude",
      valueFormatter: (params) => params.value?.toFixed(7),
    },
    {
      headerName: "Created",
      field: "created",
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };
  return (
    <div className="my-5">
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={transformTargetCities}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <TargetCitiesForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default TargetCitiesTable;
