import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule }  from "@ag-grid-community/client-side-row-model"
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import { useDisclosure, useToast } from "@chakra-ui/react";
import moment from "moment";
import {
  useAllBreeds,
  useDeleteBreedMutation,
} from "../../../../features/Breeds/Breeds.js";
import { GlobalModalV1 } from "../../../../components/GlobalModal.jsx";
import BreedForm from "./BreedForm";
import { useAllCategory } from "../../../../features/Category/Category.js";

const TableBreed = () => {
  const { data: breeds, isLoading, error } = useAllBreeds("/api/breeds/");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {}, [breeds]);

  const filterProduct = breeds?.map((product, index) => ({
    ...product,
    id: index + 1,
    createdAt: moment(product.createdAt).format(
      "dddd, MMMM Do YYYY, h:mm:ss a",
    ),
  }));
  console.log(filterProduct, "filterProduct");

  console.log(breeds, "breeds");
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const toast = useToast();

  const createDeleteMutation = useDeleteBreedMutation();

  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/breed/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Breed Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Breed");
          toast({
            title: "Error deleted Breed",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleUpdate = () => {
    const filter = filterProduct?.filter((item) => item?._id == selectedIdEdit);
    console.log(filter, "filterfilterfilter");
    setSelectedData(filter);
    setSelectedIdEdit(null);
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
      onOpen();
    };
    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handle}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => setSelectedId(props.data._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "ID",
      field: "id",
      // checkboxSelection: true,
      // floatingFilter: false,
    },
    {
      headerName: "Name",
      field: "breedInformation.breedName",
    },
    {
      headerName: "Category",
      field: "breedInformation.category.name",
    },
    {
      headerName: "Heading",
      field: "breedDetails.heading",
    },
    {
      headerName: "Pet Plan Id",
      field: "breedInformation.petPlanBreedId",
    },
    {
      headerName: "Active",
      field: "breedInformation.isActive",
    },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading products</p>;

  return (
    <div className="my-10">
      <div className="my-5 flex flex-wrap justify-between">
        <div>
          <Dropdown trigger={["click"]} menu={menuProps}>
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                ID Check
                <MdKeyboardArrowDown />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="flex flex-wrap gap-4">
          <Space direction="vertical">
            <DatePicker placeholder="Order Date" />
          </Space>
          <Space direction="vertical">
            <DatePicker placeholder="Disposition" />
          </Space>
          <Space direction="vertical">
            <DatePicker placeholder="Arrival Date" />
          </Space>
          <Dropdown trigger={["click"]} menu={menuProps}>
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Shipping
                <MdKeyboardArrowDown />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div>
          <Dropdown trigger={["click"]} menu={menuProps} paddingXS>
            <Button onClick={(e) => e.preventDefault()}>
              <Space>
                Remaining
                <MdKeyboardArrowDown />
              </Space>
            </Button>
          </Dropdown>
        </div>
        <div className="flex gap-4">
          <Button>Download Travel Document</Button>
          <Button>Send ID Verification</Button>
        </div>
      </div>

      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
        modules={[ClientSideRowModelModule]}
          rowData={filterProduct}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
      {/* <IModal
        isOpen={isOpen}
        onClose={onClose}
        selectedData={selectedData}
        isUpdateModalCategory={true}
        isCategoryModal={true}
      /> */}

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"100%"}>
        <BreedForm
          onClose={onClose}
          isUpdateModal={true}
          selectedData={selectedData}
        />
      </GlobalModalV1>
    </div>
  );
};

export default TableBreed;
