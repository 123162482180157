import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import {
  useCreateProducts,
  useUpdateProductsMutation,
} from "../../../features/Products/productsService";

const { TextArea } = Input;

const OrdersForm = ({ onClose, selectedData, isUpdateModal }) => {
  const { data: products } = useAllBreeds("/api/products/");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateProducts();
  const UpdateMutation = useUpdateProductsMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        description: selectedData[0]?.description ?? null,
        id: selectedData[0]?.id ?? null,
        name: selectedData[0]?.name ?? null,
        price: selectedData[0]?.price ?? null,
        wholesaleCost: selectedData[0]?.wholesaleCost ?? null,
        storeSold: selectedData[0]?.storeSold ?? null,
        nkSold: selectedData[0]?.nkSold ?? null,
        inventoryCost: selectedData[0]?.inventoryCost ?? null,
        displayOrder: selectedData[0]?.displayOrder ?? null,
        active: selectedData[0]?.active ?? null,
        required: selectedData[0]?.required ?? null,
        preSelected: selectedData[0]?.preSelected ?? null,
        noSalesTax: selectedData[0]?.noSalesTax ?? null,
        notes: selectedData[0]?.notes ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      description: values.description ?? null,
      id: values.id ?? null,
      name: values.name ?? null,
      price: values.price ?? null,
      wholesaleCost: values.wholesaleCost ?? null,
      storeSold: values.storeSold ?? null,
      nkSold: values.nkSold ?? null,
      inventoryCost: values.inventoryCost ?? null,
      displayOrder: values.displayOrder ?? null,
      active: values.active ?? null,
      required: values.required ?? null,
      preSelected: values.preSelected ?? null,
      noSalesTax: values.noSalesTax ?? null,
      notes: values.notes ?? null,
    };

    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/product/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Product Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/product",
          data,
        });

        toast({
          title: "Product created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Product Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Product",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Products Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Description" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="id"
              label="ID"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="ID" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Price" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="wholesaleCost"
              label="Wholesale Cost"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Wholesale Cost" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="storeSold"
              label="Store Sold"
              initialValue={0}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Store Sold" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="nkSold"
              label="NK Sold"
              initialValue={0}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="NK Sold" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="inventoryCost"
              label="Inventory Cost"
              initialValue={0}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Inventory Cost" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="displayOrder"
              label="Display Order"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Display Order" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="active"
              valuePropName="checked"
              label="Active"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="required"
              valuePropName="checked"
              label="Required"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 2 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="preSelected"
              valuePropName="checked"
              label="Pre-Selected"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="noSalesTax"
              valuePropName="checked"
              label="No Sales Tax"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Notes" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default OrdersForm;
