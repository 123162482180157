import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import {
  useCreateProducts,
  useUpdateProductsMutation,
} from "../../../features/Products/productsService";

const { TextArea } = Input;

const PupsForm = ({ onClose, selectedData, isUpdateModal }) => {
  const { data: products } = useAllBreeds("/api/products/");
  
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreateProducts();
  const UpdateMutation = useUpdateProductsMutation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      form.setFieldsValue({
        // media: selectedData[0]?.media[0] ?? null,
        title: selectedData[0]?.title ?? null,
        description: selectedData[0]?.description ?? null,
        id: selectedData[0]?.id ?? null,
        name: selectedData[0]?.name ?? null,
        price: selectedData[0]?.price ?? null,
        wholesaleCost: selectedData[0]?.wholesaleCost ?? null,
        storeSold: selectedData[0]?.storeSold ?? null,
        nkSold: selectedData[0]?.nkSold ?? null,
        inventoryCost: selectedData[0]?.inventoryCost ?? null,
        displayOrder: selectedData[0]?.displayOrder ?? null,
        active: selectedData[0]?.active ?? null,
        required: selectedData[0]?.required ?? null,
        preSelected: selectedData[0]?.preSelected ?? null,
        noSalesTax: selectedData[0]?.noSalesTax ?? null,
        notes: selectedData[0]?.notes ?? null,
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const data = {
      title: values.title ?? null,
      description: values.description ?? null,
      id: values.id ?? null,
      name: values.name ?? null,
      price: values.price ?? null,
      wholesaleCost: values.wholesaleCost ?? null,
      storeSold: values.storeSold ?? null,
      nkSold: values.nkSold ?? null,
      inventoryCost: values.inventoryCost ?? null,
      displayOrder: values.displayOrder ?? null,
      active: values.active ?? null,
      required: values.required ?? null,
      preSelected: values.preSelected ?? null,
      noSalesTax: values.noSalesTax ?? null,
      notes: values.notes ?? null,
    };

    if (media) {
      const primaryPhotoUrls = await uploadFiles(media);
      data.media = primaryPhotoUrls.length ? primaryPhotoUrls[0] : null;
    }
    try {
      if (isUpdateModal && selectedData) {
        await UpdateMutation.mutateAsync({
          apiUrl: `/api/product/${selectedData[0]?._id}`,
          data,
        });

        toast({
          title: "Product Updated successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await createMutation.mutateAsync({
          apiUrl: "/api/product",
          data,
        });

        toast({
          title: "Product created successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      // Reset form and clear states if needed
      form.resetFields();
      onClose();
    } catch (error) {
      toast({
        title: error.response?.data?.details?.includes("duplicate")
          ? "Product Already Exists"
          : error.response?.data?.details ||
            error.message ||
            "Error creating Product",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [media, setMedia] = useState([]);

  return (
    <div className="auto mx-0 mt-10 overflow-y-auto">
      <Form
        form={form}
        // layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">
          Products Information
        </h1>
        <div className="m-auto flex w-[100%] justify-between gap-4 pt-10">
          <div className="w-full">
            <Form.Item
              label="media"
              name={"media"}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                listType="picture-card"
                fileList={media}
                beforeUpload={(file) => {
                  setMedia((prev) => [...prev, file]);
                  return false; // Prevent automatic upload
                }}
                onRemove={(file) => {
                  setMedia(media.filter((f) => f.uid !== file.uid));
                }}
                multiple={true} // Ensure only one file can be uploaded
                disabled={isLoading}
              >
                {media?.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Title" disabled={isLoading} />
            </Form.Item>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Description
            </div>
            <Form.Item
              name="description"
              label="Description"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                rows={8}
                placeholder="Description"
                disabled={isLoading}
              />
            </Form.Item>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Meta
            </div>

            <Form.Item
              name="id"
              label="ID"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="ID" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name="price"
              label="Price"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Price" disabled={isLoading} />
            </Form.Item>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Wholesale Cost
            </div>

            <Form.Item
              name="wholesaleCost"
              label="Wholesale Cost"
              rules={[{ required: true }]}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Wholesale Cost" disabled={isLoading} />
            </Form.Item>

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name="storeSold"
                label="Store Sold"
                initialValue={0}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Store Sold" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="nkSold"
                label="NK Sold"
                initialValue={0}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="NK Sold" disabled={isLoading} />
              </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-5">
              <Form.Item
                name="inventoryCost"
                label="Inventory Cost"
                initialValue={0}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="Inventory Cost" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="displayOrder"
                label="Display Order"
                rules={[{ required: true }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input placeholder="0" disabled={isLoading} />
              </Form.Item>
            </div>

            <Form.Item
              name="active"
              valuePropName="checked"
              label="Active"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="required"
              valuePropName="checked"
              label="Required"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 2 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="preSelected"
              valuePropName="checked"
              label="Pre-Selected"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="noSalesTax"
              valuePropName="checked"
              label="No Sales Tax"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 1 }}
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="Notes" disabled={isLoading} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button className="!bg-[#001219] !text-white" type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PupsForm;
