import React from "react";

const Conversations = () => {
  return (
    <div className="">
      <div className="overflow-hidden rounded-[20px] border border-black/50">
        <textarea
          name="writeEmail"
          id=""
          rows="6"
          placeholder="write email here"
          className="w-full overflow-hidden p-4 outline-none"
        />
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <button className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2">
          Cancel
        </button>
        <button className="self-start rounded-lg bg-black px-4 py-2 text-white">
          Send Email
        </button>
      </div>
    </div>
  );
};

export default Conversations;
