import React from 'react'
import InsuranceTables from './InsuranceTable'

const Paid = () => {
  return (
    <>
    <div>Paid</div>
    <InsuranceTables />
    </>
  )
}

export default Paid