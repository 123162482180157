import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { GET_ALL_WHOLESALES } from "./wholesalesTypes"
import { create, Delete, fetchAll, update } from "../Apis/Apis"





export const useAllWholeSales = (apiUrl) =>{
    return useQuery({
        queryKey: [GET_ALL_WHOLESALES],
        queryFn: () => fetchAll(apiUrl)
    })
}

export const useCreateWholeSales = ()=>{
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: create,
        onMutate: (new_wholesale) => {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onError: (new_wholesale, error, context) => {
            console.log('Error creating whole sale', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        }
    })
}

export const useUpdateWholeSales = ()=>{
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: update,
        onMutate: (new_wholesale)=>{
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onError: (new_wholesale, error, context) => {
            console.log('Error updating whole sale', error)
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onSettled:()=>{
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        }
    })
}

export const useDeleteWholeSales = ()=>{
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: Delete,
        onMutate: ()=>{
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onError: (new_wholesale, error, context) => {
            console.log('Error deleting whole sales')
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        },
        onSettled: ()=> {
            queryClient.invalidateQueries(GET_ALL_WHOLESALES)
        }
    })
}