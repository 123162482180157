import React, { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";
import TargetCitiesForm from "./components/TargetCitiesForm";
import TargetCitiesTable from "./components/TargetCitiesTable";

const TargetCities = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <button
          onClick={onOpen}
          className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
        >
          Add New Cities
        </button>

        <GlobalModalV1 onClose={onClose} isOpen={isOpen} width={"800px"}>
          <TargetCitiesForm onClose={onClose} isOpen={isOpen} />
        </GlobalModalV1>
      </div>
      <TargetCitiesTable />
    </div>
  );
};

export default TargetCities;
