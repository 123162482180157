import React from "react";
import ChakraTabs from "../../../../../../components/ChakraTabs";
import OrderDetails from "../summary/innertabpages/OrderDetails";
import MGRAlert from "../sales/innertabpages/MGRAlert";
import Survey from "../summary/innertabpages/Survey";
import InstagramAccounts from "./innerTabPages/InstagramAccounts";
import Timelines from "../sales/innertabpages/Timelines";
import Refund from "../sales/innertabpages/Refund";
import Conversations from "../sales/innertabpages/Conversations";

const Feedback = ({ data }) => {
  const tabDataPrimary = [
    {
      label: "Order Details",
      content: <OrderDetails data={data} />,
    },

    {
      label: "MGR Alert",
      content: <MGRAlert />,
    },
    {
      label: "Survey",
      content: <Survey />,
    },
    {
      label: "Instagram Accounts",
      content: <InstagramAccounts />,
    },
    {
      label: "Timeline",
      content: <Timelines />,
    },
  ];
  const tabDataSecondary = [
    {
      label: "Refund",
      content: <Refund />,
    },
    {
      label: "Conversation",
      content: <Conversations />,
    },
  ];

  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      <div className="col-span-2 flex flex-col gap-10">
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataSecondary} />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
