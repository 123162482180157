import React, { useMemo } from "react";
import BreederFooterInfo from "../components/BreederFooterInfo";
import { useAllPages } from "../features/Page/pageServices";
// import ReactHtmlParser from "react-html-parser";
import DOMPurify from "dompurify";
import "quill/dist/quill.snow.css";

const Warranty = () => {
  const { data: page, error, isLoading } = useAllPages("/api/pages");
  console.log("Page data===>", page);

  const warantyData = page?.find((waranty) => waranty?.slug === "waranty");
  console.log("Waranty Data===>", warantyData);

  // const processedContent = warantyData?.content.replace(/class/g,"className")
  const sanitizedContent = useMemo(
    () => ({
      __html: DOMPurify.sanitize(warantyData?.content),
    }),
    [warantyData?.content],
  );

  // const sanitizedData = DOMPurify.sanitize(warantyData?.content);

  return (
    <div className="poppins-regular relative w-full">
      <div
        className="absolute left-0 top-0 h-[650px] w-full bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/images/top-banner-contact.png')" }}
      ></div>

      <div className="relative z-10 flex w-full flex-col items-center justify-center px-4 pt-56 md:px-32">
        <h6 className="text-3xl lowercase md:text-5xl">
          {warantyData?.heading}
        </h6>
        <h3 className="my-6 text-2xl font-bold">
          Simply Southern Pups / 10 YEAR GENETIC WARRANTY
        </h3>
        <div className="my-28 flex w-full max-w-5xl flex-col">
          {/* <div className="text-justify leading-8">
            <h1 className="my-6 text-center text-3xl font-semibold text-primaryBlack-theme/50">
              10 YEAR PUPPY REPLACEMENT GUARANTEE
            </h1>
            <p className="font-medium">
              In order to qualify for our puppy replacement guarantee we require
              you to insure your puppy for a minimum of 6 months from the date
              of purchase. With proof of major medical Pet Insurance in place
              for the first 6 months of owning your new puppy we will offer a
              replacement puppy guarantee for the first 10 years of your puppies
              life for any conditions related to Hereditary or Congenital
              abnormalities. Enrollment must occur and coverage must be in place
              prior to taking possession in order to qualify.
            </p>
            <h4 className="my-10 text-center text-xl font-semibold text-primaryBlack-theme/50">
              CASH MEDICAL REIMBURSEMENT GUARANTEE
            </h4>
            <p>
              <span className="font-semibold">
                Simply Southern Pups will provide cash medical reimbursement for
                any issue related to hereditary or congentital disorders for as
                long as you remain insured with Major Medical pet insurance.
                This means we will cover 100% of your deductible up to $300
                (standard policy) and co-pay for any covered hereditary or
                congenital abnormality for up to 10 years. There are no vet
                clinic restrictions.
                <br />
                <br />
                The puppy you have selected is also guaranteed by us to the
                original purchaser to be healthy at the time of purchase. In
                accordance with the above, We also gaurantee your puppy to be
                free from Parvo Virus, Distemper, Hepatitis, Corona Virus, for
                10 days from the day of arrival. Should your veterinarian detect
                any of the above mentioned diseases within the 10 day period
                immediately following your purchase, you must notify us within
                twenty four hours (803-830-6109). Simply Southern Pups will
                cover 100% of your co-pay and deductible for any viral related
                illness that occurs within the first 10 days as long as you have
                enrolled in Major Medical Pet Insurance prior to taking
                posession of your puppy.
                <br />
                <br />
                You must keep your puppy under regular and continued care of a
                licensed veterinarian in order to qualify for Hereditary &
                Congenital cash medical reimbursement ($300 deductible & 100% of
                Co-Pay) you must remain insured through Major Medical from the
                time of purchase.
              </span>
              <br />
              <br />
              We need your cooperation. You must take responsibility for your
              pet. Our warranty stipulates you must have your puppy examined by
              a<u> licensed veterinarian within 7 days of taking possession.</u>
              (PLEASE NO VACCINES UNTIL SECOND VISIT.) After reviewing the
              records we have provided and evaluating your puppy, your
              veterinarian will provide a schedule of the remaining preventative
              care steps.
              <br />
              <br />
              Some problems do not become apparent until later in your puppy's
              development. This can happen with any puppy regardless of breed,
              sex, or genetic background. These issues are some of the hardest
              to understand and accept. We want you to know we will be here to
              help should something like a congenital or hereditary disorder
              arise within the guarantee period. We will help, provided you have
              been responsible in your duties as a new pet owner which includes
              insuring your new fur baby.
              <br />
              <br />
              *IN THE CASE OF SUDDEN DEATH WITH NO APPARENT CAUSE, WE REQUIRE A
              NECROPSY TO BE PERFORMED IN ORDER TO FILE A FULL REPLACEMENT
              WARRANTY CLAIM. AS A COURTESY, SIMPLY SOUTHERN PUPS WILL OFFER A
              50% DISCOUNT OFF THE PURCHASE OF A NEW PUPPY (UP TO $2000) IF YOU
              ARE UNABLE TO GET AN NECROPSY PREFORMED.
              <br />
              <br />
              No cash refunds. Your warranty offers a replacement puppy in the
              event of a hereditary or congentital issue of equal or less value
              if you insured your puppy for the first 6 months a major medical
              pet insurance. In the case of Hereditary or Congenital issue which
              can be corrected we will provide cash medical reimbursement up to
              the price paid for the puppy (not including extras: shipping, etc)
              as long as you are enrolled in Major Medical Pet Insurance at the
              time of purchase and have remained insured.
              <br />
              <br />
              BREEDING: If you choose to breed your puppy that is at your own
              discretion. We do not guarantee the puppy to be fit for breeding.
              We adopt puppies with the intention they will be family pets.
              <br />
              <br />
              To file a warranty claim please email vet diagnosis to:
              sales@simplysouthernpups.com
              <br />
              <br />
              IMPORTANT: YOU MUST ENROLL IN Major Medical Pet INSURANCE TO
              QUALIFY FOR CASH MEDICAL REIMBURSEMENT
              <br />
              <br />
              Jurisdiction Clause - Any civil action against Simply Southern
              Pups must be filed within the state of South Carolina.
            </p>
          </div> */}
          <div
            className="text-justify leading-8"
            dangerouslySetInnerHTML={sanitizedContent}
          />
          {/* <div>{ReactHtmlParser(sanitizedData)}</div> */}
        </div>
        <BreederFooterInfo />
      </div>
    </div>
  );
};

export default Warranty;
