import React from "react";
import Table from "../../../components/Table";
import Table3 from "../../../components/Table3";
import { useAllInsurance } from "../../../features/Insurance/insuranceServices";
import InsuranceTables from "./InsuranceTable";

const Call = () => {

  const {data: insurances, error, isLoading} = useAllInsurance('/api/insurances');
  console.log('insurances', insurances)
  return (
    <div>
      <div className="grid grid-cols-2 gap-4 lg:grid-cols-7">
        <div className="col-span-2 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Disposition Day</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">Home</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">8</p>
                <p className="text-sm font-semibold text-[#001219]/50">0-3</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">4-7</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">8-4</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">4</p>
                <p className="text-sm font-semibold text-[#001219]/50">14+</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-3 rounded-[20px] bg-white">
          <div className="flex flex-col p-12">
            <div className="flex items-center gap-2">
              <span className="rounded-full bg-[#D9D9D9]/50 p-3"></span>
              <p className="font-bold">Wholesale Transfer Week</p>
            </div>
            <div className="flex justify-between gap-6 pt-8">
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">
                  Arrived
                </p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">6</p>
                <p className="text-sm font-semibold text-[#001219]/50">0</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">21</p>
                <p className="text-sm font-semibold text-[#001219]/50">1</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">12</p>
                <p className="text-sm font-semibold text-[#001219]/50">2</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">7</p>
                <p className="text-sm font-semibold text-[#001219]/50">6</p>
              </div>
              <div className="flex flex-col gap-2 text-center">
                <p className="text-2xl font-bold">7</p>
                <p className="text-sm font-semibold text-[#001219]/50">4</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Table3 /> */}
      <InsuranceTables />
    </div>
  );
};

export default Call;
