import React from 'react'
import InsuranceTables from './InsuranceTable'

const Certificate = () => {
  return (
    <>
      <div>Certificate</div>
      <InsuranceTables />
    </>
  )
}

export default Certificate