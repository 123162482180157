import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Switch,
  Upload,
} from "antd";
import { useToast } from "@chakra-ui/react";

import { uploadFiles } from "../../../utils/uploadFile";

import { CiSquareRemove } from "react-icons/ci";
import {
  useCreatePups,
  useUpdatePupsMutation,
} from "../../../features/pups/pupsService";
import { useAllBreeds } from "../../../features/Breeds/Breeds";
import moment from "moment";
import dayjs from "dayjs";
import { breederOptions } from "../../../utils/data";

const { TextArea } = Input;

const OfferForm = ({ onClose, selectedData, isUpdateModal }) => {
  const { data: breeds } = useAllBreeds("/api/breeds/");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const createMutation = useCreatePups();
  const UpdateMutation = useUpdatePupsMutation();

  const [form] = Form.useForm();

  const [video, setVideo] = useState([]);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    if (selectedData && isUpdateModal) {
      const dateAcquired = selectedData[0]?.puppyInformation?.dateAcquired
        ? dayjs(selectedData[0].puppyInformation.dateAcquired)
        : null;

      const dateOfBirth = selectedData[0]?.puppyInformation?.dateOfBirth
        ? dayjs(selectedData[0].puppyInformation.dateOfBirth)
        : null;
      form.setFieldsValue({
        puppyInformation: {
          breed: selectedData[0]?.puppyInformation?.breed._id,
          breeder: selectedData[0]?.puppyInformation?.breeder,
          kennel: selectedData[0]?.puppyInformation?.kennel,
          cageNumber: selectedData[0]?.puppyInformation?.cageNumber,
          color: selectedData[0]?.puppyInformation?.color,
          idNumber: selectedData[0]?.puppyInformation?.idNumber,
          usdaId: selectedData[0]?.puppyInformation?.usdaId,
          localPickup: selectedData[0]?.puppyInformation?.localPickup,
          gender: selectedData[0]?.puppyInformation?.gender,
          registration: selectedData[0]?.puppyInformation?.registration,
          akcAppNumber: selectedData[0]?.puppyInformation?.akcAppNumber,
          dateAcquired: dateAcquired,
          dateOfBirth: dateOfBirth,
          video: selectedData[0]?.puppyInformation?.video,
          weight: selectedData[0]?.puppyInformation?.weight,
          testResults1: selectedData[0]?.puppyInformation?.testResults1 ?? null,
          meds1: selectedData[0]?.puppyInformation?.meds1 ?? null,
          testResults2: selectedData[0]?.puppyInformation?.testResults2 ?? null,
          meds2: selectedData[0]?.puppyInformation?.meds2 ?? null,
          testResults3: selectedData[0]?.puppyInformation?.testResults3 ?? null,
          meds3: selectedData[0]?.puppyInformation?.meds3 ?? null,
          testResults4: selectedData[0]?.puppyInformation?.testResults4 ?? null,
          meds4: selectedData[0]?.puppyInformation?.meds4 ?? null,
          dateSold: selectedData[0]?.puppyInformation?.dateSold
            ? dayjs(selectedData[0]?.puppyInformation?.dateSold)
            : null,
          wholesaleTransferDate: selectedData[0]?.puppyInformation
            ?.wholesaleTransferDate
            ? dayjs(selectedData[0]?.puppyInformation?.wholesaleTransferDate)
            : null,
          purchaser: selectedData[0]?.puppyInformation?.purchaser,
          cost: selectedData[0]?.puppyInformation?.cost,
        },
        listingInformation: {
          active: selectedData[0]?.listingInformation?.active,
          featured: selectedData[0]?.listingInformation?.featured,
          perfect10: selectedData[0]?.listingInformation?.perfect10,
          doNotList: selectedData[0]?.listingInformation?.doNotList,
          nurturingNotice: selectedData[0]?.listingInformation?.nurturingNotice,
          heading: selectedData[0]?.listingInformation?.heading,
          name: selectedData[0]?.listingInformation?.name,
          title: selectedData[0]?.listingInformation?.title,
          description: selectedData[0]?.listingInformation?.description,
          whatComesWithDog:
            selectedData[0]?.listingInformation?.whatComesWithDog,
          price: selectedData[0]?.listingInformation?.price,
          adultWeight: selectedData[0]?.listingInformation?.adultWeight,
          quantity: selectedData[0]?.listingInformation?.quantity,
          makeOffer: selectedData[0]?.listingInformation?.makeOffer,
          offerPrice: selectedData[0]?.listingInformation?.offerPrice,
          photos: selectedData[0]?.listingInformation?.photos,
          registrationPaperwork:
            selectedData[0]?.listingInformation?.registrationPaperwork,
          presalePups: selectedData[0]?.listingInformation?.presalePups,
        },
      });
    }
  }, [selectedData, isUpdateModal]);

  const onFinish = async (values) => {
    setIsLoading(true);
    console.log(values, "values");

    const { puppyInformation, listingInformation } = values;

    // Prepare data object
    const data = {
      puppyInformation: {
        breed: puppyInformation?.breed ?? null,
        breeder: puppyInformation?.breeder ?? null,
        kennel: puppyInformation?.kennel ?? null,
        cageNumber: puppyInformation?.cageNumber ?? null,
        color: puppyInformation?.color ?? null,
        idNumber: puppyInformation?.idNumber ?? null,
        usdaId: puppyInformation?.usdaId ?? null,
        localPickup: puppyInformation?.localPickup ?? null,
        gender: puppyInformation?.gender ?? null,
        registration: puppyInformation?.registration ?? null,
        akcAppNumber: puppyInformation?.akcAppNumber ?? null,
        dateAcquired: puppyInformation?.dateAcquired ?? null,
        dateOfBirth: puppyInformation?.dateOfBirth ?? null,
        video: video?.length ? video[0] : null,
        weight: puppyInformation?.weight ?? null,
        testResults1: puppyInformation?.testResults1 ?? null,
        meds1: puppyInformation?.meds1 ?? null,
        testResults2: puppyInformation?.testResults2 ?? null,
        meds2: puppyInformation?.meds2 ?? null,
        testResults3: puppyInformation?.testResults3 ?? null,
        meds3: puppyInformation?.meds3 ?? null,
        testResults4: puppyInformation?.testResults4 ?? null,
        meds4: puppyInformation?.meds4 ?? null,
        dateSold: puppyInformation?.dateSold ?? null,
        wholesaleTransferDate: puppyInformation?.wholesaleTransferDate ?? null,
        purchaser: puppyInformation?.purchaser ?? null,
        cost: puppyInformation?.cost ?? null,
      },
      listingInformation: {
        active: listingInformation?.active ?? null,
        featured: listingInformation?.featured ?? null,
        perfect10: listingInformation?.perfect10 ?? null,
        doNotList: listingInformation?.doNotList ?? null,
        nurturingNotice: listingInformation?.nurturingNotice ?? null,
        heading: listingInformation?.heading ?? null,
        name: listingInformation?.name ?? null,
        title: listingInformation?.title ?? null,
        description: listingInformation?.description ?? null,
        whatComesWithDog: listingInformation?.whatComesWithDog ?? null,
        price: listingInformation?.price ?? null,
        adultWeight: listingInformation?.adultWeight ?? null,
        quantity: listingInformation?.quantity ?? null,
        makeOffer: listingInformation?.makeOffer ?? null,
        offerPrice: listingInformation?.offerPrice ?? null,
        photos: photos?.length ? photos : null,
        registrationPaperwork:
          listingInformation?.registrationPaperwork ?? null,
        presalePups: listingInformation?.presalePups ?? null,
      },
    };

    if (photos) {
      const photoUrls = await uploadFiles(photos);
      data.listingInformation.photos = photoUrls.length ? photoUrls : null;
    }

    if (video) {
      const videoUrls = await uploadFiles(video);
      data.puppyInformation.video = videoUrls.length ? videoUrls[0] : null;
    }

    if (isUpdateModal && selectedData) {
      UpdateMutation.mutate(
        {
          apiUrl: `/api/puppy/${selectedData[0]?._id}`,
          data,
        },
        {
          onSuccess: () => {
            setVideo([]);
            setPhotos([]);
            form.resetFields();
            toast({
              title: "Puppy Updated successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Puppy Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error Updating Puppy",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    } else {
      createMutation.mutate(
        {
          apiUrl: "/api/puppy",
          data,
        },
        {
          onSuccess: () => {
            setVideo([]);
            setPhotos([]);
            form.resetFields();
            toast({
              title: "Puppy created successfully!",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            onClose();
          },
          onError: (error) => {
            toast({
              title: error.response?.data?.details?.includes("duplicate")
                ? "Puppy Already Exist"
                : error.response?.data?.details ||
                  error.message ||
                  "Error creating Puppy",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          },
        },
      );
    }

    setIsLoading(false);
  };

  const breedOptions = breeds?.map((breed) => ({
    value: breed._id,
    label: breed?.breedInformation?.breedName,
  }));

  const options = [
    "Presale Pups",
    "FFB",
    "PR",
    "SDC",
    "WDC",
    "SEA",
    "LSP",
    "PGP",
    "PP CHICAGO",
    "PP MIAMI",
    "PP OHIO",
  ];

  return (
    <div className="auto mx-0 mt-10 min-h-screen overflow-y-auto">
      <Form
        form={form}
        // wrapperCol={{ span:  }}
        layout="horizontal"
        className="m-auto flex w-full flex-col items-center justify-center rounded-xl border border-gray-400 p-6"
        onFinish={onFinish}
      >
        <h1 className="pb-6 text-center text-xl font-semibold">Offers</h1>
        <div className="m-auto grid grid-cols-2 gap-20 pt-10">
          <div>
            <div>
              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Offers
              </div>

              <Form.Item
                name="offerNotes"
                label="Offer Notes"
                rules={[{ required: true }]}
              >
                <TextArea
                  placeholder="Offer Notes"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name="acceptableAmount"
                label="Acceptable Amount"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Acceptable Amount"
                  style={{ width: "100%" }}
                  allowClear
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Initial Sales Call
              </div>
              <Form.Item name="availabilityDate" label="Availability Date">
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item name="initialNotes" label="Initial Notes">
                <TextArea
                  placeholder="Initial Notes"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item name="initials" label="Initials">
                <Input placeholder="Initials" disabled={isLoading} />
              </Form.Item>

              <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
                Order Information
              </div>

              <Form.Item name="pupID" label="Pup ID">
                <Input placeholder="ID Number" disabled={isLoading} />
              </Form.Item>

              <div className="grid grid-cols-2 gap-5">
                <Form.Item name="orderDate" label="Order Date">
                  <DatePicker style={{ width: "100%" }} disabled={isLoading} />
                </Form.Item>

                <Form.Item name="orderDatePIF" label="Order Date PIF">
                  <DatePicker style={{ width: "100%" }} disabled={isLoading} />
                </Form.Item>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <Form.Item name="pickUpArrival" label="PickUp / Arrival">
                  <DatePicker style={{ width: "100%" }} disabled={isLoading} />
                </Form.Item>

                <Form.Item name="customerConfirmedTransportDate" label="Customer Confirmed Transport Date" valuePropName="checked">
                <Checkbox style={{ width: "100%" }}  disabled={isLoading}></Checkbox>
                </Form.Item>
              </div>

              <Form.Item
                name="airportCode"
                label="Airport Code"
              >
               <Input placeholder="Airport Code" disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name="airlines"
                label="Airlines"
              >
                <Select
                  placeholder="American"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "American", value: "American" },
                    { label: "Delta", value: "Delta" },
                    { label: "United", value: "United" },
                    { label: "Alaska Airlines", value: "Alaska Airlines" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name="specialInstructions"
                label="Special Instructions"
              >
                <TextArea
                  placeholder="Special Instructions"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <div className="grid grid-cols-2 gap-5">
                <Form.Item name="flightNumber" label="Flight Number">
                <Input placeholder="Flight Number" disabled={isLoading} />
                </Form.Item>

                <Form.Item name="trackingNumber" label="Tracking Number">
                <Input placeholder="Tracking Number" disabled={isLoading} />
                </Form.Item>
                <Form.Item name="emailAddress" label="Email Address">
                <Input placeholder="Email Address" disabled={isLoading} />
                </Form.Item>

                <Form.Item name="alternateEmail" label="Alternate Email">
                <Input placeholder="Alternate Email" disabled={isLoading} />
                </Form.Item>
                <Form.Item name="transactionID" label="Transaction ID">
                <Input placeholder="Transaction ID" disabled={isLoading} />
                </Form.Item>

                <Form.Item name="additionalCharges" label="Additional Charges">
                <Input placeholder="Additional Charges" disabled={isLoading} />
                </Form.Item>
                <Form.Item
                name="arrivalMethod"
                label="Arrival Method"
              >
                <Select
                  placeholder="Local"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Local", value: "Local" },
                    { label: "Nanny Airport Delivery", value: "Nanny Airport Delivery" },
                    { label: "Priority Airport Delivery", value: "Priority Airport Delivery" },
                    { label: "Pro-Stop Pickup Point", value: "Alaska Airlines" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              </div>


              <Form.Item
                name={["puppyInformation", "dateAcquired"]}
                label="Date Acquired"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "dateOfBirth"]}
                label="Date of Birth"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item name={["puppyInformation", "video"]} label="Video">
                <Upload
                  listType="picture-card"
                  fileList={video}
                  beforeUpload={(file) => {
                    setVideo([file]);
                    return false; // Prevent automatic upload
                  }}
                  onRemove={(file) => {
                    setVideo([]);
                  }}
                  disabled={isLoading}
                >
                  {video.length === 0 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
              </Form.Item>

              <Form.Item name={["puppyInformation", "weight"]} label="Weight">
                <Input placeholder="Weight in lbs" disabled={isLoading} />
              </Form.Item>

              {/* <Form.Item
                name={["puppyInformation", "testResults"]}
                label="Test Results"
              >
                <TextArea
                  placeholder="Test Results"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item name={["puppyInformation", "meds"]} label="Meds">
                <TextArea
                  placeholder="Medications"
                  rows={4}
                  disabled={isLoading}
                />
              </Form.Item> */}

              <Form.Item
                name={["puppyInformation", "testResults1"]}
                label="Test Results 1"
              >
                <Select
                  placeholder="Test Results 1"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds1"]} label="meds1">
                <Select
                  placeholder="meds1"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "testResults2"]}
                label="Test Results 2"
              >
                <Select
                  placeholder="Test Results 2"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds2"]} label="meds2">
                <Select
                  placeholder="meds2"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "testResults3"]}
                label="Test Results 3"
              >
                <Select
                  placeholder="Test Results 3"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds3"]} label="meds3">
                <Select
                  placeholder="meds3"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "testResults4"]}
                label="Test Results 4"
              >
                <Select
                  placeholder="Test Results 4"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item name={["puppyInformation", "meds4"]} label="meds4">
                <Select
                  placeholder="meds4"
                  style={{ width: "100%" }}
                  allowClear
                  options={[{ label: "Abnormality", value: "Abnormality" }]}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                name={["puppyInformation", "dateSold"]}
                label="Date Sold"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "wholesaleTransferDate"]}
                label="Wholesale Transfer Date"
              >
                <DatePicker disabled={isLoading} />
              </Form.Item>

              <Form.Item
                name={["puppyInformation", "purchaser"]}
                label="Purchaser"
              >
                <Select
                  placeholder="Purchaser"
                  style={{ width: "100%" }}
                  allowClear
                  options={[
                    { label: "Capital City Pups", value: "Capital City Pups" },
                    { label: "Puppy Love Pr", value: "Puppy Love Pr" },
                  ]}
                  disabled={isLoading}
                />
              </Form.Item>

              <Form.Item name={["puppyInformation", "cost"]} label="Cost">
                <Input placeholder="Cost" disabled={isLoading} />
              </Form.Item>
            </div>
          </div>

          <div>
            <div className="mb-4 w-full rounded-md bg-[#001219] px-4 py-2 text-sm font-semibold text-white">
              Listing Information
            </div>
            {/* Breed Details */}
            <Form.Item
              name={["listingInformation", "active"]}
              valuePropName="checked"
              label="Active"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "featured"]}
              valuePropName="checked"
              label="Featured"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "perfect10"]}
              valuePropName="checked"
              label="Perfect 10"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "doNotList"]}
              valuePropName="checked"
              label="Do Not List"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "nurturingNotice"]}
              label="Nurturing Notice"
            >
              <TextArea
                placeholder="Nurturing Notice"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "heading"]}
              label="Heading"
              rules={[{ required: true }]}
            >
              <Input placeholder="Listing Heading" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["listingInformation", "name"]} label="Name">
              <Input placeholder="Puppy Name" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["listingInformation", "title"]} label="Title">
              <Input placeholder="Listing Title" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "description"]}
              label="Description"
            >
              <TextArea
                placeholder="Listing Description"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "whatComesWithDog"]}
              label="What Comes With Dog"
            >
              <TextArea
                placeholder="Items included with dog"
                rows={4}
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "price"]}
              label="Price"
              rules={[{ required: true }]}
            >
              <Input placeholder="Price" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "adultWeight"]}
              label="Adult Weight"
            >
              <Input placeholder="Adult Weight in lbs" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "quantity"]}
              label="Quantity"
            >
              <Input placeholder="Quantity" disabled={isLoading} />
            </Form.Item>

            <Form.Item
              name={["listingInformation", "makeOffer"]}
              valuePropName="checked"
              label="Make Offer"
            >
              <Checkbox disabled={isLoading}></Checkbox>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "offerPrice"]}
              label="Offer Price"
            >
              <Input placeholder="Offer Price" disabled={isLoading} />
            </Form.Item>

            <Form.Item name={["listingInformation", "photos"]} label="Photos">
              <Upload
                listType="picture-card"
                fileList={photos}
                beforeUpload={(file) => {
                  setPhotos((prev) => [...prev, file]);
                  return false;
                }}
                onRemove={(file) => {
                  setPhotos(photos.filter((f) => f.uid !== file.uid));
                }}
                multiple={true}
                disabled={isLoading}
              >
                {photos.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <Form.Item
              name={["listingInformation", "presalePups"]}
              label="PreSale Pups"
            >
              <Checkbox.Group options={options} defaultValue={["Apple"]} />
            </Form.Item>
          </div>
        </div>
        <div className="">
          <Form.Item>
            <Button
              className="!bg-[#001219] !text-white"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default OfferForm;
