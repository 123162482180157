import React from "react";
import PendingOffers from "./components/PendingOffers";
import RejectedOffers from "./components/RejectedOffers";
import ChakraTabs from "../../components/ChakraTabs";

const Offers = () => {
  const tabData = [
    {
      label: "Pending",
      content: <PendingOffers />,
    },
    {
      label: "Rejected",
      content: <RejectedOffers />,
    },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default Offers;
