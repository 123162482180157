import React, { useState } from "react";
import TableLocation from "./TableLocation";
import AddNewLocationPage from "./AddNewLocationPage";
import { useDisclosure } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import AddLocationForm from "./AddLocationForm";

const Locations = () => {
  const [addLocation, setShowAddLocation] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAddLocation = () => {
    setShowAddLocation(!addLocation);
  };
  return (
    <>
      <button
        onClick={onOpen}
        className="mt-5 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        + Add New Location
      </button>
      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <AddLocationForm isOpen={isOpen} onClose={onClose} />
      </GlobalModalV1>
      <TableLocation />
      {/* {addLocation ? (
        <AddNewLocationPage />
      ) : (
        <div className="flex flex-1 flex-col">
          <button
            onClick={handleAddLocation}
            className="self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
          >
            Add New Location
          </button>
          <TableLocation />
        </div>
      )} */}
    </>
  );
};

export default Locations;
