import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { footerInfo } from "../utils/data";

const Footer = () => {
  return (
    <div className="poppins-regular nunito-sans min-h-[682px] w-full bg-primaryBlack-theme py-4">
      {/* <div className="relative mt-8 flex flex-col px-6 py-8 sm:px-32">
        <div className="flex flex-col">
          <a href="#" className="flex gap-3">
            <img src="/images/lOGO-white.png" alt="logo" width={120} />
          </a>
        </div>
        <div className="mt-24 grid grid-cols-1 gap-6 text-white sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div className="flex gap-2 text-white">
            <AiFillInstagram size={26} />
            <p className="font-semibold"> Follow Us </p>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Contact Info</h3>
              <p className="text-lg font-normal">Payment Agents 803-830-6109</p>
            </div>
            <div>
              <h3 className="pb-6 text-xl font-bold">Our Address</h3>
              <p className="text-lg font-normal">
                187 Conifer Drive Prosperity SC 29127
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Simply Southern Pups</h3>
              <div className="space-y-3 text-lg font-normal">
                <p>Available Puppy Listing</p>
                <p>Local Pickup</p>
                <p>Customer Reviews</p>
                <p>Puppy Photo Gallery</p>
                <p>Our Team</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="text-lg font-normal">
              <h3 className="pb-6 text-xl font-bold">Learn About Our Breeds</h3>
              <p>Breed Information</p>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div>
              <h3 className="pb-6 text-xl font-bold">Puppy Health</h3>
              <div className="space-x-3 text-lg font-normal">
                <p>10 Year Health Guarantee</p>
                <p>Pet Insurance</p>
                <p>Pre-Screen Blood Tests</p>
                <p>DNA Testing</p>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute -bottom-24 right-[280px] flex gap-[250px] py-6 text-xl font-semibold text-white">
          <p>Terms Of Use</p>
          <p>Privacy Policy</p>
        </div>
      </div> */}
      <div className="relative mt-8 flex flex-col px-6 py-8 sm:px-32">
        <div className="flex flex-col">
          <a href="/" className="flex gap-3">
            <img src="/images/lOGO-white.png" alt="logo" width={120} />
          </a>
        </div>
        <div className="mt-24 grid grid-cols-1 gap-6 text-white sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
          <div className="block">
            <a
              href="https://www.instagram.com/simplysouthernpups"
              target="_blank"
              rel="noopener noreferrer"
              className="flex gap-2 text-white"
            >
              <AiFillInstagram size={28} />
              <p className="font-semibold"> Follow Us </p>
            </a>
          </div>

          {footerInfo.map((section, index) => (
            <div key={index} className="flex flex-col gap-8">
              <div>
                <h3 className="pb-6 text-xl font-bold">{section.title}</h3>
                <div className="space-y-3 text-lg font-normal">
                  {section.items.map((item, itemIndex) => (
                    <p key={itemIndex}>
                      {item.value ? (
                        <>
                          <span>{item.label}: </span>
                          <a href={item.link} className="hover:underline">
                            {item.value}
                          </a>
                        </>
                      ) : (
                        <a href={item.link} className="hover:underline">
                          {item.label}
                        </a>
                      )}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="absolute -bottom-24 right-[280px] flex gap-[250px] py-6 text-xl font-semibold text-white">
          <p>Terms Of Use</p>
          <p>Privacy Policy</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
