import React from "react";
import TableMethods from "./TableMethods";

const Methods = () => {
  return (
    <div className="my-10">
      <TableMethods />
    </div>
  );
};

export default Methods;
