import React from "react";
import Call from "./components/Call";
import Enrolled from "./components/Enrolled";
import EnOther from "./components/EnOther";
import Declined from "./components/Declined";
import Paid from "./components/Paid";
import Certificates from "./components/Certificates";
import Settings from "./components/Settings";
import ChakraTabs from "../../components/ChakraTabs";

const Insurance = () => {
  const tabData = [
    {
      label: "Call",
      content: <Call />,
    },
    {
      label: "Enrolled",
      content: <Enrolled />,
    },
    {
      label: "En-Other",
      content: <EnOther />,
    },
    {
      label: "Declined",
      content: <Declined />,
    },
    {
      label: "Paid",
      content: <Paid />,
    },
    {
      label: "Certificates",
      content: <Certificates />,
    },
    // {
    //   label: "Settings",
    //   content: <Settings />,
    // },
  ];
  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default Insurance;
