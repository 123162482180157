import React, { useEffect, useState } from "react";

import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { Button, DatePicker, Dropdown, Space } from "antd";

import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useAllDeliveries, useDeleteDeliveries } from "../../../features/deliveries/deliveriesServices";
import { useToast } from "@chakra-ui/react";

const TableDeliveries = () => {

  const [selectedId, setSelectedId] = useState(null);
  const toast = useToast();

  const {data: Deliveries, isLoading, error } = useAllDeliveries('/api/deliveries')
  console.log('Deliveries===>', Deliveries);

  const deleteMutation = useDeleteDeliveries();

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    
  }, [selectedId]);

  const transformDeliveries = Deliveries?.map((delivery, index)=>({
    _id: delivery?._id ?? "",
    dispositionDate: delivery?.dispositionDate ?? "",
    customer: delivery?.customer ?? "",
    shipMethod: delivery?.shipMethod ?? "",
    airlines: delivery?.airlines ?? "",
    depart: delivery?.depart ?? "",
    dTime: delivery?.dTime ?? "",
    CTN: delivery?.CTN ?? "",
    arrives: delivery?.arrives ?? "",
    FL: delivery?.FL ?? "",
    arrivalDate: delivery?.arrivalDate ?? "",
    delivery: delivery?.delivery ?? "",
    kenDim: delivery?.kenDim ?? "",
    AANotes: delivery?.AANotes ?? ""
  })) ?? []
  
  const handleDelete = () => {
    deleteMutation.mutate(
      { apiUrl: `/api/delivery/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "Deliveries Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted Deliveries");
          toast({
            title: "Error deleted Deliveries",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };


  const btnComponent = (props) => {
    // const handleEdit = () => {
    //   setSelectedIdEdit(props?.data?._id);
    //   onOpen();
    // };

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          //   onClick={handleEdit}
        >
          <AiFillEdit />
        </button>
        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
            onClick={() => setSelectedId(props?.data?._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const columns = [
    { headerName: "ID", field: "id", editable: true, width: 100 },
    { headerName: "Disposition Date", field: "dispositionDate", editable: true },
    { headerName: "Customer", field: "customer", editable: true },
    { headerName: "Ship Method", field: "shipMethod", editable: true },
    { headerName: "Airlines", field: "airlines", editable: true },
    { headerName: "Depart", field: "depart", editable: true },
    { headerName: "D-Time", field: "dTime", editable: true },
    { headerName: "CTN", field: "CTN", editable: true },
    { headerName: "Arrives", field: "arrives", editable: true },
    { headerName: "FL#", field: "FL", editable: true, width: 80 },
    { headerName: "Arrival Date", field: "arrivalDate", editable: true, width: 80 },
    { headerName: "Delivery", field: "delivery", editable: true, width: 80 },
    { headerName: "Ken Dim", field: "kenDim", editable: true, width: 80 },
    { headerName: "AA Notes.", field: "AANotes", editable: true, width: 80 },
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];
 
  const items = [
    {
      label: "1st menu item",
      key: "1",
    },
    {
      label: "2nd menu item",
      key: "2",
    },
    {
      label: "3rd menu item",
      key: "3",
      danger: true,
    },
    {
      label: "4th menu item",
      key: "4",
      danger: true,
      disabled: true,
    },
  ];
  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div className="my-10">
     
      <div
        className="ag-theme-quartz"
        style={{ height: 500, overflowX: "auto" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={transformDeliveries}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />
      </div>
    </div>
  );
};

export default TableDeliveries;
