import React from 'react'
import InsuranceTables from './InsuranceTable'

const EnOther = () => {
  return (
    <>
    <div>EnOther</div>
    <InsuranceTables />
    </>
  )
}

export default EnOther