import React from "react";
import ChakraTabs from "../../components/ChakraTabs";
import Available from "./components/Available";
import PupsTable from "./components/PupsTable";
import PupListingDashboard from "./components/PupListingDashboard";

const Pups = () => {
  const tabData = [
    {
      label: "Dashboard",
      content: <PupListingDashboard />,
    },
    {
      label: "Available",
      content: <Available />,
    },
    {
      label: "All Pups",
      content: <PupsTable />,
    },
  ];

  return (
    <div>
      <ChakraTabs tabData={tabData} />
    </div>
  );
};

export default Pups;
