import React from "react";
import ChakraTabs from "../../../../../../components/ChakraTabs";
import OrderDetails from "../summary/innertabpages/OrderDetails";
import CustomerInfo from "../sales/innertabpages/CustomerInfo";
import MGRAlert from "../sales/innertabpages/MGRAlert";
import DeliveryInfo from "./innerTabPages/DeliveryInfo";
import Kenneling from "../sales/innertabpages/Kenneling";
import Timelines from "../sales/innertabpages/Timelines";
import PickUpAuthorization from "./innerTabPages/PickUpAuthorization";
import IdVerification from "./innerTabPages/IdVerification";
import LocalPickup from "./innerTabPages/LocalPickup";
import Refund from "../sales/innertabpages/Refund";
import Conversations from "../sales/innertabpages/Conversations";

const Delivery = ({ data }) => {
  const tabDataPrimary = [
    {
      label: "Order Details",
      content: <OrderDetails data={data} />,
    },
    {
      label: "Customer Info",
      content: <CustomerInfo />,
    },
    {
      label: "Delivery Info",
      content: <DeliveryInfo />,
    },
    {
      label: "MGR Alert",
      content: <MGRAlert />,
    },

    {
      label: "Kenneling",
      content: <Kenneling />,
    },
    {
      label: "Timeline",
      content: <Timelines />,
    },
  ];
  const tabDataSecondary = [
    {
      label: "Pickup Authorization",
      content: <PickUpAuthorization />,
    },
    {
      label: "ID Verification",
      content: <IdVerification />,
    },
    {
      label: "Local Pickup Date",
      content: <LocalPickup />,
    },
  ];

  const tabDataTertiary = [
    {
      label: "Refund",
      content: <Refund />,
    },
    {
      label: "Conversation",
      content: <Conversations />,
    },
  ];
  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      <div className="col-span-2 flex flex-col gap-10">
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataSecondary} />
        </div>
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataTertiary} />
        </div>
      </div>
    </div>
  );
};

export default Delivery;
