import React from "react";
import Table from "../../../components/Table";
import Table3 from "../../../components/Table3";
import TableOffer from "./TableOffer";

const PendingOffers = () => {
  return (
    <div>
      {/* <Table3 /> */}
      <TableOffer />
    </div>
  );
};

export default PendingOffers;
