import React, { useState } from "react";

import { Link } from "react-router-dom";
import {
  menuItemBreeder,
  menuItemCheckout,
  menuItemPups,
  menuItemSetting,
  menuItemsSales,
  menuItemUtilities,
  menuItemWebsiteContent,
  menuItemTracking,
} from "../utils/data";

import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import {
  MdArrowBackIos,
  MdArrowForwardIos,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useSelector } from "react-redux";

const SidebarCustom = ({ subpage }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const handleSidebarMenu = () => {
    setIsCollapsed(!isCollapsed);
  };
  const { user } = useSelector((state) => state.auth);
  return (
    <div
      className={`no-scrollbar sticky top-0 h-screen overflow-y-auto bg-white transition-all duration-300 ${
        isCollapsed ? "w-72" : "w-28"
      } flex-shrink-0`}
    >
      <div
        className={`flex items-center p-4 ${isCollapsed ? "justify-between" : "justify-center"}`}
      >
        {isCollapsed && (
          <span className="text-lg font-bold">
            {user.name ? user.name : "Admin"}
          </span>
        )}
        <button
          onClick={handleSidebarMenu}
          className="rounded-full p-2 duration-75 hover:bg-[#D9D9D9]/50"
        >
          {isCollapsed ? (
            <MdOutlineKeyboardArrowLeft size={28} />
          ) : (
            <MdOutlineKeyboardArrowRight size={28} />
          )}
        </button>
      </div>
      <div
        className={`flex w-full flex-col justify-center ${isCollapsed ? "pl-10" : "items-center justify-center pl-0"}`}
      >
        <img src="/images/logo.svg" alt="logo" width={80} />
        <div className="mr-12 flex border-b border-gray-100 pb-8" />
      </div>
      <div className="mt-4 space-y-2">
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Sales & Services
          </h3>
          {menuItemsSales.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
              key={index}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}
            </Link>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Pup Listing
          </h3>
          {menuItemPups.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
              key={index}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
            </Link>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Tracking
          </h3>
          {menuItemTracking.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
              key={index}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
            </Link>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            NK / Breeder Management
          </h3>
          {menuItemBreeder.map((menuItem, index) => (
            <Link
            to={`/admin/${menuItem.link}`}
            className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
            key={index}
          >
             
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
      
            </Link>
           
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Checkout
          </h3>
          {menuItemCheckout.map((menuItem, index) => (
            <Link
              to={`/admin/${menuItem.link}`}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
              key={index}
            >
              <menuItem.icon size={22} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
            </Link>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Website Content
          </h3>
          {menuItemWebsiteContent.map((menuItem, index) => (
            <div
              key={index}
              className={`"}`}
            >
              <Link
                to={`/admin/${menuItem.link}`}
                className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
                key={index}
              >
                <menuItem.icon size={25} />
                {isCollapsed && (
                  <p className="capitalize">{menuItem.menuLabel}</p>
                )}
              </Link>
            </div>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Settings
          </h3>
          {menuItemSetting.map((menuItem, index) => (
            <div
              key={index}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
            </div>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
        <div className="flex flex-col gap-2 py-4">
          <h3
            className={`font-semibold ${isCollapsed ? "pl-10 text-lg" : "px-2 text-center text-xs"}`}
          >
            Utilities
          </h3>
          {menuItemUtilities.map((menuItem, index) => (
            <div
              key={index}
              className={`flex items-center justify-start gap-3 font-semibold text-[#001219]/50 duration-100 hover:bg-[#001219] hover:text-white ${isCollapsed ? "py-2 pl-10" : "mx-6 justify-center rounded-lg py-3"} ${subpage === menuItem.link && "bg-[#001219] text-white"}`}
            >
              <menuItem.icon size={25} />
              {isCollapsed && (
                <p className="capitalize">{menuItem.menuLabel}</p>
              )}{" "}
            </div>
          ))}
          <div className="mr-12 flex border-b border-gray-100 pb-4" />
        </div>
      </div>
    </div>
  );
};

export default SidebarCustom;
