import React from "react";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";

const TableMethods = () => {
  const btnComponent = (props) => {
    return (
      <div className="flex gap-5 pt-2">
        <button className="rounded-full bg-[#35f8fe] p-1 text-white">
          <AiFillEdit />
        </button>

        <button className="rounded-full bg-[#EE5D50] p-1 text-white">
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const tableColumnData = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Restricted Breeds",
      field: "restrictedBreeds",
    },

    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];
  const tableRowData = [
    {
      name: "John F. Kennedy International Airport",
      restrictedBreeds: "JFK",
    },
    {
      name: "Los Angeles International Airport",
      restrictedBreeds: "LAX",
    },
    {
      name: "Chicago O'Hare International Airport",
      restrictedBreeds: "ORD",
    },
    {
      name: "Dallas/Fort Worth International Airport",
      restrictedBreeds: "DFW",
    },
    {
      name: "Denver International Airport",
      restrictedBreeds: "DEN",
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };
  return (
    <div className="ag-theme-quartz my-5" style={{ height: 500 }}>
      <AgGridReact
        modules={[ClientSideRowModelModule]}
        rowData={tableRowData}
        columnDefs={tableColumnData}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 25, 50]}
      />
    </div>
  );
};

export default TableMethods;
