import React from "react";

const Timeline = ({ steps, currentStep }) => {
  return (
    <div className="mt-16 mb-44 flex items-center justify-center">
      {steps.map((step, index) => (
        <div key={index} className="flex items-center">
          <div className="relative flex items-center">
            <div
              className={`flex h-6 w-6 items-center justify-center rounded-full text-sm text-white transition duration-300 ${
                currentStep >= index ? "bg-primaryBlack-theme" : "bg-black/50"
              }`}
            >
              {index + 1}
            </div>
            {index < steps.length - 1 && (
              <div className="h-[2px] md:w-72 w-16 flex-1 transition duration-300">
                <div
                  className={`h-full ${
                    currentStep > index ? "bg-black" : "bg-gray-300"
                  }`}
                ></div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Timeline;
