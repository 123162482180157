import React from "react";
import CustomTabs from "../../../../components/CustomTabs";
import Summary from "./tabPages/summary/Summary";
import PupInfo from "./tabPages/pupinfo/PupInfo";
import Sales from "./tabPages/sales/Sales";
import Health from "./tabPages/health/Health";
import Delivery from "./tabPages/delivery/Delivery";
import Request from "./tabPages/request/Request";
import Feedback from "./tabPages/feedback/Feedback";
import { useLocation } from "react-router-dom";
import { convertAge, getPuppyAgeInWeeks } from "../../../../utils";
import { useCreateINVOICE } from "../../../../features/Invoice/invoiceService";
import { useToast } from "@chakra-ui/react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";






const OrdersDashboard = ({ handleShowDashboard, data }) => {
  const tabs = [
    { label: "Summary", content: <Summary data={data} /> },
    { label: "Pup Info", content: <PupInfo /> },
    { label: "Sales", content: <Sales data={data} /> },
    { label: "Health", content: <Health data={data} /> },
    { label: "Delivery", content: <Delivery data={data} /> },
    { label: "Request", content: <Request /> },
    { label: "Feedback", content: <Feedback data={data} /> },
  ];
  const tabClassName =
    "bg-white text-[#001219] rounded-full py-2 mr-4 md:mr-10 md:text-base text-xs px-6 md:px-10 py-3 z-0";
  const activeTabClassName =
    "bg-[#001219] text-white rounded-full py-2 mr-4 md:mr-10 px-6 md:text-base text-xs md:px-10 py-3 z-10";
  const tabPositionClassName = "flex flex-col justify-center pt-10";

  console.log(data, "summaryData");


const createMutation = useCreateINVOICE()
const toast = useToast();

const handleSubmit = async ()=>{

  console.log(data?.contactInformation?.emailAddress)

const invoiceData = {
  email: data?.contactInformation?.emailAddress,
  subtotal: data?.orderSummary?.subtotal,
  firstName: data?.billingAddress?.firstName,
  cellPhone: data?.contactInformation?.cellPhone,
  orderId: data?.orderId,
  coupon: data?.orderSummary?.coupon,
  orderTotal : data?.orderSummary?.orderTotal,
  cost: data?.puppies?.[0]?.listingInformation?.price ?? 'Price not available',
  products: data?.products
}

  try {
    await createMutation.mutateAsync({
      apiUrl: "/api/email-invoice",
      data:invoiceData,
    });

    toast({
      title: "email invoice send successfully!",
      status: "success",
      duration: 3000,
    
    });
  } catch (error) {
    console.log(error.message)
    toast({
      title: "email invoice send failed!",
      status: "failed",
      duration: 3000,
      
    });
  }
}

// print
const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});


  return (
    <div className="my-8">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-8 " >
        <div className="col-span-3 grid grid-cols-2 gap-5 rounded-[20px] bg-white">
          <div className="h-full w-full overflow-hidden">
            <img
              src={
                data?.puppies?.[0]?.listingInformation?.photos ||
                "/images/breed1-2.jpeg"
              }
              alt="breed"
              className="h-full w-full rounded-[20px] object-cover"
            />
          </div>
          <div className="flex p-8">
            {data?.puppies?.map((item, index) => (
              <div className="leading-7" key={index}>
                <p className="text-lg font-bold text-[#DE0796]">
                  {item?.listingInformation?.name}
                </p>
                <p className="font-bold">
                  {item.puppyInformation.gender} - Shih Poo
                </p>
                <p>
                  {item?.puppyInformation?.weight ||
                    "" + "lbs" ||
                    "" +
                      "" +
                      `@${getPuppyAgeInWeeks(item?.puppyInformation?.dateOfBirth) || ""}  }weeks`}
                </p>
                <p>
                  {`Est Adult:  ${item?.listingInformation?.adultWeight || "" + "lbs" || ""}`}
                </p>
                <br />
                <p className="font-semibold">PS - Cozy Creek Charm</p>
                <p className="font-extrabold">Available: 8/3/2024</p>
                <button className="mt-2 rounded-lg bg-[#001219] px-4 py-2 text-white">
                  Send Request
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-2 flex flex-col justify-between rounded-[20px] bg-white p-12">
          <div className="flex items-center gap-3">
            <img src="/images/pickup-icon.svg" alt="pickup-icon" />
            <p className="font-bold">Pickup</p>
          </div>
          <div className="flex items-center gap-12">
            <div className="text-center">
              <p className="text-lg font-bold">Insurance</p>
              <p className="text-xl font-bold">12</p>
            </div>
            <div className="text-center">
              <p className="text-lg font-bold">Alerts</p>
              <p className="text-xl font-bold">8</p>
            </div>
          </div>
        </div>
        <div className="justify-items-between col-span-3 grid grid-cols-2 gap-4 items-center rounded-[20px] bg-white p-12">
          <div>
            <p className="text-lg font-bold">
              {data.destinationAddress.firstName +
                "" +
                data.destinationAddress.lastName}
            </p>
            <p className="text-lg font-bold">804-349-9236</p>
          </div>
          <div>
            <p>
              Order ID <strong>{data?.orderId}</strong>
            </p>
            <p>
              Order date <strong>{convertAge(data.createdAt)}</strong>
            </p>
          </div>
          <div className="leading-7">
            <p className="text-sm">Address</p>
            <p className="font-bold">
              {data.destinationAddress.address} {data.destinationAddress.city}{" "}
              {data.destinationAddress.state}
            </p>
            <p className="pt-2 text-sm">Email</p>
            <p className="font-bold">{data.contactInformation.emailAddress}</p>
          </div>
          <div className="flex flex-col gap-2">
          <button className="mt-2 rounded-lg bg-[#001219] px-4 py-2 text-white" onClick={handlePrint}>Print article</button>
            <button 
            onClick={()=>handleSubmit()}
            className="mt-2 rounded-lg bg-[#001219] px-4 py-2 text-white">
              Email Invoice
            </button>
          </div>
        </div>
      </div>
      <div className="my-6 " ref={componentRef}>
        <CustomTabs
          tabs={tabs}
          tabClassName={tabClassName}
          activeTabClassName={activeTabClassName}
          tabPositionClassName={tabPositionClassName}
        />
      </div>

      <button onClick={handleShowDashboard}>Close</button>
    </div>
  );
};

export default OrdersDashboard;
