import React, { useEffect, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { AiFillEdit } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllDiscounts,
  useDeleteDISCOUNTMutation,
} from "../../../features/DiscountsCoupon/discountServise";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { GlobalModalV1 } from "../../../components/GlobalModal";
import AntModal from "../../../components/AntModal";
import DiscountsForm from "./DiscountsForm";

const TableDiscounts = () => {
  const [showPage, setShowPage] = useState(false);
  const {
    data: discounts,
    isLoading,
    error,
  } = useAllDiscounts("/api/discounts");
  console.log("discounts==>", discounts);
  const deleteMutation = useDeleteDISCOUNTMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState(null);
  const toast = useToast();

  const pageHandler = () => {
    setShowPage(!showPage);
  };

  const handleDelete = () => {
    setActionType("delete");
    setIsModalVisible(true);
  };

  const handleUpdate = () => {
    const filter = discounts?.filter((item) => item?._id === selectedIdEdit);
    setSelectedData(filter);
    setSelectedIdEdit(null);
    onOpen();
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
    }
    if (selectedIdEdit) {
      handleUpdate();
    }
  }, [selectedId, selectedIdEdit]);

  const handleConfirm = async () => {
    setIsModalVisible(false);
    deleteMutation.mutate(
      { apiUrl: `/api/discount/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);
          toast({
            title: "Discount Coupon Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted discount coupon");
          toast({
            title: "Error deleted discount coupon",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setActionType(null);
    setSelectedId(null);
  };

  const btnComponent = (props) => {
    const handleEdit = () => {
      console.log(props?.data?._id)
      setSelectedIdEdit(props?.data?._id);
    };

    const handleDeleteClick = () => {
      setSelectedId(props?.data?._id);
    };

    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handleEdit}
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={handleDeleteClick}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const columns = [
    { headerName: "Coupon Code", field: "couponCode" },
    { headerName: "Value(percentage)", field: "valuePercentage" },
    { headerName: "Offset Weeks", field: "offsetWeeks" },
    { headerName: "Restricted Breeds", field: "restrictedBreeds" },
    { headerName: "Created", field: "created" },
    { headerName: "Expires", field: "expires" },
    {
      headerName: "Inhouse Only",
      field: "inHouseOnly",
    },
    {
      headerName: "Hand Delivery",
      field: "handDelivery",
    },
    {
      headerName: "One Time Only",
      field: "oneTimeOnly",
    },
    { headerName: "Auto Apply", field: "autoApply" },
    {
      headerName: "Presale Only",
      field: "preSaleOnly",
    },
    {
      headerName: "Allowed On Dep",
      field: "allowedOnDep",
    },
    {
      headerName: "Available Now Only",
      field: "availableNowOnly",
    },
    {
      headerName: "Actions",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },
  ];

  const DiscountRows =
    discounts?.map((discount, index) => ({
      _id: discount?._id ?? "",
      couponCode: discount?.couponCode ?? "",
      valuePercentage: discount.value ?? "",
      offsetWeeks: discount?.offsetWeeks ?? "",
      restrictedBreeds: discount?.restrictedBreeds ?? "",
      expires: discount?.expireDate ?? "",
      couponType: discount?.couponType ?? "",
      shippingOnly: discount?.shippingOnly ?? "",
      handDelivery: discount?.withHandDeliveryAlso ?? "",
      inHouseOnly: discount?.inHouseOnly ?? "",
      preSaleOnly: discount?.preSaleOnly ?? "",
      oneTimeOnly: discount?.oneTimeOnly ?? "",
      autoApply: discount?.autoApply ?? "",
      allowedOnDep: discount?.allowedOnDeposits ?? "",
      availableNowOnly: discount?.availableNowOnly ?? "",
      created: discount?.createdAt ?? "",
    })) ?? [];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div className="my-5">
      <div
        className="ag-theme-quartz"
        style={{ height: 500, overflowX: "auto" }}
      >
        <AgGridReact
          modules={[ClientSideRowModelModule]}
          rowData={DiscountRows}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
        />

        <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
          <DiscountsForm
            onClose={onClose}
            isUpdateModal={true}
            selectedData={selectedData}
          />
        </GlobalModalV1>

        <AntModal
          centered
          isOpen={isModalVisible}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          title={
            actionType === "approve" ? "Confirm Approval" : "Confirm Deletion"
          }
          content={
            actionType === "approve"
              ? "Are you sure you want to approve this Discount?"
              : "Are you sure you want to delete this Discount?"
          }
          okText={"Confirm"}
        />
      </div>
    </div>
  );
};

export default TableDiscounts;
