import React from "react";
import CustomerInfo from "./innertabpages/CustomerInfo";
import DeliveryService from "./innertabpages/DeliveryService";
import Insurance from "./innertabpages/Insurance";
import Extras from "./innertabpages/Extras";
import BlueSalesCall from "./innertabpages/BlueSalesCall";
import Refund from "./innertabpages/Refund";
import Conversations from "./innertabpages/Conversations";
import MGRAlert from "./innertabpages/MGRAlert";
import Kenneling from "./innertabpages/Kenneling";
import Timeline from "./innertabpages/Timelines.jsx";
// import OrderDetails from "./summary/innertabpages/OrderDetails";
import ChakraTabs from "../../../../../../components/ChakraTabs.jsx"
import OrderDetails from "../summary/innertabpages/OrderDetails.jsx";
import Timelines from "./innertabpages/Timelines.jsx";

const Sales = ({data}) => {
  const tabDataPrimary = [
    {
      label: "Order Details",
      content: <OrderDetails data={data} />,
    },
    {
      label: "Customer Info",
      content: <CustomerInfo />,
    },
    {
      label: "Delivery Service",
      content: <DeliveryService />,
    },
    {
      label: "Insurance",
      content: <Insurance />,
    },
    {
      label: "Extras",
      content: <Extras />,
    },
    {
      label: "Timeline",
      content: <Timelines />,
    },
  ];
  const tabDataSecondary = [
    {
      label: "Blue Sales Call",
      content: <BlueSalesCall />,
    },
    {
      label: "Refund",
      content: <Refund />,
    },
    {
      label: "Conversations",
      content: <Conversations />,
    },
  ];

  const tabDataTertiary = [
    {
      label: "MGR Alert",
      content: <MGRAlert />,
    },
    {
      label: "Kenneling",
      content: <Kenneling />,
    },
   
  ];
  return (
    <div className="my-10 grid grid-cols-1 gap-10 md:grid-cols-5">
      <div className="col-span-3 min-h-[800px] w-full rounded-[20px] bg-white p-10">
        <ChakraTabs tabData={tabDataPrimary} />
      </div>
      <div className="col-span-2 flex flex-col gap-10">
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataSecondary} />
        </div>
        <div className="min-h-[300px] w-full overflow-y-auto rounded-[20px] bg-white p-10">
          <ChakraTabs tabData={tabDataTertiary} />
        </div>
      </div>
    </div>
  );
};

export default Sales;
