import React, { useState, useEffect } from "react";
import { Button, DatePicker, Dropdown, Space } from "antd";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model"
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import { LiaTimesSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import GlobalLoader from "../../../components/GlobalLoader.jsx";
import { AiFillEdit } from "react-icons/ai";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { GlobalModalV1 } from "../../../components/GlobalModal.jsx";
import { useAllReviews, useCreateOrders } from "../../../features/reviews/reviewsService.js";
// import OrdersDashboard from "./OrderDetail/OrdersDashboard.jsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const TableReviews = () => {
  const { data: reviews, isLoading, error } = useAllReviews("/api/reviews");
  const [breedName, setBreedName] = useState(null);

  
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [isShowDetail, setIsShowDetail] = useState({});
  // const navigate = useNavigate();

  const transformReviews =
    reviews?.map((review, index) => ({
      _id: review?._id,
      id: index + 1,
      pupName: review?.breed?.breedInformation?.breedName ?? "",
      chip: review?.chip ?? "",
      dateOfBirth: review?.dateOfBirth ?? "",
      heading: review?.heading ?? "",
      firstName: review?.firstName ?? "",
      lastName: review?.lastName ?? "",
      cellPhone: review?.cellPhone ?? "",
      email: review?.email ?? "",
      sms: review?.sms ?? "",
      do_not_send: review?.do_not_send ?? "",
      pupNewName: review?.pupNewName ?? "",
      weight: review?.weight ?? "",
      year_review_notes: review?.year_review_notes ?? "",
      initials: review?.initials ?? "",

    })) ?? [];

  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [activeTab, setActiveTab] = useState(0); // To keep track of the active tab
  const toast = useToast();


  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    console.log(props?.data, "props?.data");
    return (
      <div className="flex gap-5 pt-2">
        <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
 
        >
          <AiFillEdit />
        </button>

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          // onClick={() => setSelectedId(props?.data?._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };

  const columns = [
    {
      headerName: "_id",
      field: "_id",
      minWidth: 150,
      hide: true,
    },
    { headerName: "Pup Name", field: "pupName", minWidth: 90 },
    { headerName: "Chip", field: "chip", minWidth: 150 },
    { headerName: "Birth Date", field: "dateOfBirth", minWidth: 150 },
    { headerName: "Heading", field: "heading", minWidth: 150 },
    { headerName: "First Name", field: "firstName", minWidth: 150 },
    { headerName: "Last Name", field: "lastName", minWidth: 150 },
    { headerName: "Cell Phone", field: "cellPhone", minWidth: 150 },
    { headerName: "Email", field: "email", minWidth: 150 },
    { headerName: "	SMS", field: "	sms", minWidth: 150 },
    { headerName: "Do not sent", field: "do_not_send", checkboxSelection: true ,minWidth: 150 },
    { headerName: "Pup New Name", field: "pupNewName", minWidth: 150 },
    { headerName: "Year Weight", field: "weight", minWidth: 150 },
    { headerName: "Year Review Notes", field: "year_review_notes", minWidth: 150 },
    { headerName: "Initials", field: "initials", minWidth: 150 },
    {
      headerName: "Action",
      cellRenderer: btnComponent,
      floatingFilter: false,
    },

  ];

 

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  const items = [
    { label: "1st menu item", key: "1" },
    { label: "2nd menu item", key: "2" },
    { label: "3rd menu item", key: "3", danger: true },
    { label: "4th menu item", key: "4", danger: true, disabled: true },
  ];

  const menuProps = {
    items,
    onClick: (e) => console.log("Click", e),
  };

  if (isLoading) return <GlobalLoader />;
  if (error) return <p>Error loading orders</p>;

  console.log(transformReviews, "transformformReviews");
  console.log(reviews, "reviews");



  return (
    <div className="my-10">
      {/* <div>
              {
                reviews ? (
                   <div>
                    {reviews.map((breed,index)=>(
              
                    console.log("breed=>>>", breed?.breed?.breedInformation?.breedName)
                    ))}
                   </div>
                ) : null
              }
            </div> */}
      <Tabs >

        <TabPanels>
          <TabPanel>
            <div
              className="ag-theme-quartz"
              style={{ height: 700, overflowX: "auto" }}
            >
              <AgGridReact
                modules={[ClientSideRowModelModule]}
                rowData={transformReviews}
                columnDefs={columns}
                defaultColDef={defaultColDef}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={50}
                paginationPageSizeSelector={[10, 25, 50]}
                domLayout="autoHeight"
              // onRowClicked={onRowClicked}
              />
            </div>
            
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default TableReviews;
