import React from 'react'
import Table3 from '../../components/Table3'

const SpecialRequest = () => {
  return (
    <div>
      <Table3 />
    </div>
  )
}

export default SpecialRequest