import React, { useEffect, useRef, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import {
  AiFillEdit,
  AiFillCheckCircle,
  AiFillCloseCircle,
} from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import {
  useAllFAQS_CATEGORY,
  useCreateFAQS_CATEGORY,
  useDeleteFAQS_CATEGORYMutation,
  useUpdateFAQS_CATEGORYMutation,
} from "../../../features/Faqs/faqsService";
import GlobalLoader from "../../../components/GlobalLoader";
import { useToast } from "@chakra-ui/react";

const ConfirmCancelButtons = ({ onConfirm, onCancel }) => (
  <div className="flex gap-5 pt-2">
    <button
      className="rounded-full bg-[#35f8fe] p-1 text-white"
      onClick={onConfirm}
    >
      <AiFillCheckCircle />
    </button>
    <button
      className="rounded-full bg-[#EE5D50] p-1 text-white"
      onClick={onCancel}
    >
      <AiFillCloseCircle />
    </button>
  </div>
);

const Categories = () => {
  const [rowData, setRowData] = useState([]);
  const [newData, setNewData] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [selectedIdEdit, setSelectedIdEdit] = useState(null);
  const [addingNew, setAddingNew] = useState(false);
  const {
    data: faqs,
    isLoading,
    error,
  } = useAllFAQS_CATEGORY("/api/faq-categories");

  const createMutation = useCreateFAQS_CATEGORY();
  const updateMutation = useUpdateFAQS_CATEGORYMutation();
  const createDeleteMutation = useDeleteFAQS_CATEGORYMutation();

  const btnComponent = (props) => {
    const handle = () => {
      setSelectedIdEdit(props?.data?._id);
    };

    console.log(props?.data, "props?.data");
    return (
      <div className="flex gap-5 pt-2">
        {/* <button
          className="rounded-full bg-[#35f8fe] p-1 text-white"
          onClick={handle}
        >
          <AiFillEdit />
        </button> */}

        <button
          className="rounded-full bg-[#EE5D50] p-1 text-white"
          onClick={() => setSelectedId(props?.data?._id)}
        >
          <LiaTimesSolid />
        </button>
      </div>
    );
  };
  const handleDelete = () => {
    createDeleteMutation.mutate(
      { apiUrl: `/api/faq-category/${selectedId}` },
      {
        onSuccess: () => {
          setSelectedId(null);

          toast({
            title: "FAQS Deleted Successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        },
        onError: (error) => {
          console.log("Error deleted FAQS");
          toast({
            title: "Error deleted FAQS",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        },
      },
    );
  };

  useEffect(() => {
    if (selectedId) {
      handleDelete();
      console.log(selectedId, "selectedId");
    }
    if (selectedIdEdit) {
      // handleUpdate();
      console.log(selectedIdEdit, "selectedIdEdit");
    }
  }, [selectedId, selectedIdEdit]);

  useEffect(() => {
    if (faqs) {
      setRowData(faqs);
    }

    console.log(faqs, "faqs");
  }, [faqs]);

  const gridRef = useRef();
  const toast = useToast();

  if (isLoading) return <GlobalLoader />;
  if (error) {
    toast({
      title: "Error fetching data.",
      description: "There was an error loading the FAQ categories.",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  }

  console.log(newData, "newData");
  console.log(selectedId, "selectedId");
  const addNewRecord = () => {
    const newItem = { name: "", isNew: true };
    setRowData([newItem, ...rowData]);
    setAddingNew(true);
  };

  const confirmAdd = async () => {
    setAddingNew(false);

    // Finalize the new rows by setting isNew to false and trimming the name
    const newRows = rowData.map((row) => {
      if (row.isNew) {
        return { ...row, isNew: false, name: row.name?.trim() };
      }
      return row;
    });

    setRowData(newRows.filter((row) => row.name?.trim() !== "" || !row.isNew));

    await createMutation.mutateAsync({
      apiUrl: "/api/faq-category",
      data: { category: newData.category },
    });

    toast({
      title: "Categories created successfully!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const cancelAdd = () => {
    setRowData(rowData.filter((row) => !row.isNew));
    setAddingNew(false);
  };

  const columns = [
    { headerName: "Name", field: "category", editable: true },
    {
      headerName: "Action",
      cellRenderer: (params) => {
        if (params.data.isNew) {
          setNewData(params.data);
          return (
            <ConfirmCancelButtons onConfirm={confirmAdd} onCancel={cancelAdd} />
          );
        }
        return btnComponent(params);
      },
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    floatingFilter: true,
  };

  return (
    <div className="my-5">
      <button
        onClick={addNewRecord}
        className="mb-2 rounded bg-[#001219] p-2 text-sm text-white"
        disabled={addingNew}
      >
        + Add New Record
      </button>
      <div className="ag-theme-quartz" style={{ height: 500 }}>
        <AgGridReact
          ref={gridRef}
          modules={[ClientSideRowModelModule]}
          rowData={rowData}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[10, 25, 50]}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};

export default Categories;
