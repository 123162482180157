import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from "@chakra-ui/react";
import { GlobalModalV1 } from "../../components/GlobalModal";
import BreedForm from "./components/Breed/BreedForm";
import TableBreed from "./components/Breed/TableBreed";
import CategoryForm from "./components/categories/CategoryForm";
import CategoryTable from "./components/categories/CategoryTable";

const BreedInfo = () => {
  const [isShowForm, setIsShowForm] = useState(false);

  return (
    <div className="my-10">
      <div className="flex flex-1 flex-col">
        <Tabs>
          <TabList>
            <Tab>Add Breed</Tab>
            <Tab>Add Category</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Breed />
            </TabPanel>
            <TabPanel>
              <Categories />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default BreedInfo;

const Breed = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="my-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        Add Breed
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose}>
        <BreedForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <TableBreed />
    </>
  );
};

const Categories = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <button
        onClick={onOpen}
        className="my-4 self-start rounded-lg border-2 border-black bg-transparent px-4 py-2"
      >
        Add Category
      </button>

      <GlobalModalV1 isOpen={isOpen} onClose={onClose} width={"800px"}>
        <CategoryForm onClose={onClose} isOpen={isOpen} />
      </GlobalModalV1>

      <CategoryTable />
    </>
  );
};
